import axios from "axios";
import React, {Component} from "react";
import ReportcardForm from "./ReportcardForm";
import DataRecordcard from "./DataRecordcard";
import Loading from "../../Loading";

class   Reportcard extends Component{

    state = {
        isModal : false,
        loading : false,
        formData : null,
        establishmentsectionclasses : [],
    }

    componentDidMount(){
        this.getEstablishmentsectionclasses();
    }

    async getEstablishmentsectionclasses(){
        var establishmentsectionclasses = await axios.get('establishmentsectionclasses');
        if(establishmentsectionclasses.status === 200)
        {
            this.setState({establishmentsectionclasses : establishmentsectionclasses.data.data, loading : true}) 
        }
    }

    showModal = (establishmentsectionclasse = null) => {
        this.setState({
            isModal : !this.state.isModal,
            formData : establishmentsectionclasse,
        })
        this.getEstablishmentsectionclasses()
    }

    delete = (establishmentsectionclasse) =>{
        axios.delete("reportcards/" + establishmentsectionclasse).then((response) => {
            this.getEstablishmentsectionclasses()
        }).catch((error)=>{})
    }

    render(){

        var establishmentsectionclasses =
        this.state.establishmentsectionclasses.map((establishmentsectionclasse) => {
           return(
                <DataRecordcard establishmentsectionclasse={establishmentsectionclasse} key={establishmentsectionclasse.id} />
            )
        })
        return(
            <div>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h4 className="h4 font-monospace">Resultats des élèves</h4>
                {/* <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                        <button type="button" className="btn btn-sm btn-outline-primary" onClick={() => this.showModal(null)}><FeatherIcon icon="plus-circle" ></FeatherIcon></button>
                    </div>
                </div> */}
            </div>

            <div className="row">
                <div className="col-md-12 ">
                    <ul className="widget-todo-list-wrapper p-0">
                        { this.state.loading ?  establishmentsectionclasses : <Loading /> }
                    </ul>
                </div>
                
            </div>
            
            <ReportcardForm
                isModal={this.state.isModal} 
                formData={this.state.formData} 
                showModal={this.showModal}
            />
            

        </div>
        )
    }
}
export default Reportcard;