import React, {Component} from "react";
import FeatherIcon from 'feather-icons-react';
import ReactToPrint from "react-to-print";
import Footerpdf from "../../users/Footerpdf";
import Headerpdf from "../../users/Headerpdf";

class DataSchendule extends Component{

    handleCheck(val) {
        var user = JSON.parse(localStorage.getItem("user"));
        return user ? user.permissions ? (user.permissions ?? []).length > 0 ? user.permissions.some(item => val === item.permission) : false : false : false;
    }

    render(){
        
        return(
            <li className="widget-todo-item list-group-item m-2 mb-4 border rounded-3">
                <div
                    className="widget-todo-title-wrapper d-flex justify-content-between align-items-center mb-50">
                    <div className="widget-todo-title-area d-flex align-items-center">
                    {
                        this.handleCheck("Ajouter horaire") == true && (
                            <div className="btn-toolbar m-0 mb-md-0">
                                <div className="btn-group">
                                    <button type="button" className="btn btn-sm text-primary" onClick={() => this.props.showModal(this.props.schedule.id)}><FeatherIcon icon="plus-circle" ></FeatherIcon></button>
                                </div>
                            </div>
                        )
                    }<FeatherIcon icon="box" className='cursor-move'></FeatherIcon>
                        <label htmlFor={this.props.schedule.id}><span className="widget-todo-title ml-50">{this.props.schedule.classe.short_entitled_class} {this.props.schedule.classe.entitled_class} <span className="text-primary">{this.props.schedule.title}</span> { this.props.schedule.section.short_entitled_section} </span></label>
                    </div>
                    <div className="widget-todo-item-action d-flex align-items-center">
                        <div className="text-end mb-2 mb-md-0">
                            <div className="btn-group me-2">
                                <ReactToPrint
                                    trigger={() => <button className="btn btn-sm btn-outline-primary" type="button"><FeatherIcon icon={"printer"} size="22"></FeatherIcon> Imprimer</button>}
                                    content={() =>  this.componentRef}
                                />
                            </div>
                        </div>
                        {this.props.schedule.section.short_entitled_section} <FeatherIcon icon="list" size="16" className='cursor-move text-dark'></FeatherIcon>
                    </div>
                </div>
                <div className="table-responsive m-2" ref={el => (this.componentRef = el)}>

                    <Headerpdf />
                <div className="userIdentity">
                    <div className="text-center h6">
                        <label htmlFor={this.props.schedule.id}><span className="widget-todo-title ml-50">Horaire {this.props.schedule.classe.short_entitled_class} {this.props.schedule.classe.entitled_class} <span className="text-primary">{this.props.schedule.title}</span> { this.props.schedule.section.short_entitled_section} </span></label>
                    </div>
                </div>
                    <table className="table table-striped table-sm">
                        <thead>
                            <tr>
                            {
                                this.props.schedule.establishmentsectionclassedays.map((establishmentsectionclasseday) => {
                                    return(<th className="border" scope="col" key={establishmentsectionclasseday.id+"eviveSoma"}> {establishmentsectionclasseday.day.day}</th>)
                                    })
                            }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.props.schedule.establishmentsectionclassedays.map((establishmentsectionclasseday) => {
                                    return (
                                            <td className="" key={establishmentsectionclasseday.id}>
                                                
                                                {
                                                    establishmentsectionclasseday.schedules.filter(schedule => this.props.category && schedule.schedulecategory.category === this.props.category || !this.props.category).map((schedule) => {
                                                        return(
                                                            <tr className="" key={schedule.id}>
                                                                <td className= { schedule.schedulecategory.category == "Examen" ? "border  border-1 border-info" : "border"} type="button" title={"modifier " + schedule.date_start + " au " + schedule.date_end } onClick={ () => this.props.showModal(schedule)}>
                                                                    { schedule.establishmentsectionclasselesson.lesson.short_entitled_lesson} <small className="fw-lighter text-primary small" style={{ fontSize : 11 +"px"}}>{schedule.schedulecategory.category == "Deuxieme session" ? "D.S" : schedule.schedulecategory.category}</small> <img src={ schedule.staff.user.avatar} title={schedule.staff.user.matricule + " "+schedule.staff.user.first_name} className="rounded-circle" height={"30px"} /> 
                                                                    <span className="fw-lighter" style={{ fontSize : 11 +"px"}}> { " "+ schedule.time_start } {schedule.time_end }</span>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </td>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                    <Footerpdf />
                </div>
            </li>
        )
    }
}

export default DataSchendule