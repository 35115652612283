import React, {Component} from "react";

class Headerpdf extends Component{

    render(){
        var establishment = JSON.parse(localStorage.getItem('establishment'))
        return(
            <div className="userIdentity">
                <div className="header">
                    <div className="d-flex bd-highlight">
                        <div className="p-2 bd-highlight">
                            <img src={establishment.logo} alt=""  className="rounded img-fluid" style={{ width:100+"px" }} />
                        </div>
                        <div className="p-2 flex-grow-1 bd-highlight">
                            <div className="">
                                <div  className="">
                                    MINISTER DE L'ENSEIGNEMENT PRIMAIRE SECONDAIRE<br />ET TECHNIQUE (EPST)
                                </div>
                                <div>{ establishment.name}</div>
                                <div className="text-secondary">{"<< "+establishment.short_name +" >>"}</div>
                                <div>Ecole { establishment.type} agréée sous l'arrêté ministérial</div>
                                <div>{establishment.identifiant_agree}</div>
                            </div>
                        </div>
                        <div className="p-2 bd-highlight">
                            <img src={"/assets/drc.png"} alt=""  className="rounded img-fluid" style={{ width:100+"px" }} />
                        </div>
                    </div>
                    
                </div>
            </div>
        )
    }
}

export default Headerpdf