import axios from "axios";
import React, {Component} from "react";
import moment from "moment";
import ReactToPrint from "react-to-print";
import FeatherIcon from 'feather-icons-react';

class   Studentreportcardfirst extends Component{

    state = {
        isModal : false,
        formData : null,
        reportcard : [],
        max : [],
    }

    componentDidMount(){
        // this.getReportcard();
        // this.max()
    }

    // async getReportcard(){
    //     var reportcard = await axios.get(`students/${this.props.student}`);
    //     if(reportcard.status === 200)
    //     {
    //         this.setState({reportcard : reportcard.data.data}) 
    //         if(this.props.reportcard)
    //             this.max()
    //     }
    // }

    // async max(){
    //     var max = await axios.get('establishmentsectionclasselessons/distinct/' + this.props.reportcard.establishmentsectionclasse.id);
    //     if(max.status === 200)
    //     {
    //         this.setState({max : max.data.data}) 
    //     }
    // }

    showModal = (reportcard = null) => {
        this.setState({
            isModal : !this.state.isModal,
            formData : reportcard,
        })
        this.getReportcard()
    }

    delete = (reportcard) =>{
        axios.delete("reportcards/" + reportcard).then((response) => {
            this.getReportcard()
        }).catch((error)=>{})
    }

    render(){
        var itemData = [],
            newtable = [], 
            somme1 = 0,
            somme2 = 0,

            periode1 =0,
            periode2 =0,
            periode3 =0,
            periode4 =0,
            periode5 =0,
            periode6 =0,
            periode7 =0,
            periode8 =0,
            
            periodeT1 =0,
            periodeT2 =0,
            periodeT3 =0,
            periodeT4 =0,
            periodeT5 =0,
            periodeT6 =0,
            periodeT7 =0,
            periodeT8 =0,
            val = 0;
        
        return(
            <div>
                {/* <div className="btn-toolbar mb-2 mb-md-0 text-end">
                    <div className="btn-group me-2">
                        <button type="button" className="btn btn-sm btn-outline-primary" onClick={() => this.showModal(null)}><FeatherIcon icon="plus-circle" ></FeatherIcon></button>
                    </div>
                </div> */}
                {/* <div className="text-end">
                    <ReactToPrint
                        trigger={() => <button className="btn btn-sm btn-outline-primary" type="button"><FeatherIcon icon={"printer"} size="22"></FeatherIcon> Imprimer</button>}
                        content={() =>  this.componentRef}
                    />
                </div> */}
                <div className="m-2">
                    <div className=" align-items-center pt-3 pb-2 mb-0 border">
                        <h6 className="h6 text-dark text-center">
                            REPUBLIQUE DEMOCRATIQUE DU CONGO <br />
                            PROVINCE DU SUD-KIVU<br />
                            MINISTERE DE L'ENSEIGNEMENT PRIMAIRE, SECONDAIRE ET PROFESSIONNEL
                        </h6>
                    </div>
                    <div className="border d-flex justify-content-between p-0">
                        <span className="h5 d-inline mt-2 ml-3 mb-1">N`ID </span>  
                        <table className="table d-inline  m-2 mt-2 mb-1">
                            <tr>
                                <td className="border px-2 " width={"40px"} height={"25px"}> </td>
                                <td className="border px-2 " width={"40px"} height={"25px"}> </td>
                                <td className="border px-2 " width={"40px"} height={"25px"}> </td>
                                <td className="border px-2 " width={"40px"} height={"25px"}> </td>
                                <td className="border px-2 " width={"40px"} height={"25px"}> </td>
                                <td className="border px-2 " width={"40px"} height={"25px"}> </td>
                                <td className="border px-2 " width={"40px"} height={"25px"}> </td>
                                <td className="border px-2 " width={"40px"} height={"25px"}> </td>
                                <td className="border px-2 " width={"40px"} height={"25px"}> </td>
                                <td className="border px-2 " width={"40px"} height={"25px"}> </td>
                                <td className="border px-2 " width={"40px"} height={"25px"}> </td>
                                <td className="border px-2 " width={"40px"} height={"25px"}> </td>
                                <td className="border px-2 " width={"40px"} height={"25px"}> </td>
                                <td className="border px-2 " width={"40px"} height={"25px"}> </td>
                                <td className="border px-2 " width={"40px"} height={"25px"}> </td>
                                <td className="border px-2 " width={"40px"} height={"25px"}> </td>
                                <td className="border px-2 " width={"40px"} height={"25px"}> </td>
                                <td className="border px-2 " width={"40px"} height={"25px"}> </td>
                                <td className="border px-2 " width={"40px"} height={"25px"}> </td>
                                <td className="border px-2 " width={"40px"} height={"25px"}> </td>
                                <td className="border px-2 " width={"40px"} height={"25px"}> </td>
                                <td className="border px-2 " width={"40px"} height={"25px"}> </td>
                            </tr>
                        </table>  
                    </div>

                    <div className="border">
                        <div className="row">
                            <div className="col-6 border-end">
                                <div className="p-2 overflow-hidden">
                                    <div className="">VILLE : UVIRA</div>
                                    <div className="">COMMUNE/TER :  </div>
                                    <div className=" overflow-hidden">ECOLE : { this.props.reportcard ? this.props.reportcard.user ? this.props.reportcard.establishmentsectionclasse.name : "" : ""} </div>
                                    <div className=" d-flex justify-content-between p-0">
                                        <span className=" d-inline mt-2 ml-3 mb-1">CODE: </span>  
                                        <table className="table d-inline  m-2 mt-2 mb-1">
                                            <tr>
                                                <td className="border px-2 " width={"40px"} height={"25px"}> </td> 
                                                <td className="border px-2 " width={"40px"} height={"25px"}> </td> 
                                                <td className="border px-2 " width={"40px"} height={"25px"}> </td> 
                                                <td className="border px-2 " width={"40px"} height={"25px"}> </td> 
                                                <td className="border px-2 " width={"40px"} height={"25px"}> </td> 
                                                <td className="border px-2 " width={"40px"} height={"25px"}> </td> 
                                                <td className="border px-2 " width={"40px"} height={"25px"}> </td> 
                                                <td className="border px-2 " width={"40px"} height={"25px"}> </td> 
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div> 
                            <div className="col-6 border-start">
                                <div className="p-2">
                                    <div className="d-flex justify-content-between overflow-hidden">

                                        <div>ELEVE :  { this.props.reportcard ? this.props.reportcard.user ? this.props.reportcard.user.first_name : "" : "" }  { this.props.reportcard ? this.props.reportcard.user ? this.props.reportcard.user.family_name : "" : "" }  { this.props.reportcard ? this.props.reportcard.user ? this.props.reportcard.user.last_name : "" : "" } </div> 
                                        <div>SEXE : </div> 
                                    </div>
                                    <div className="d-flex justify-content-between overflow-hidden">
                                        <div>NE(E) A : { this.props.reportcard ? this.props.reportcard.user ? this.props.reportcard.user.adresse : "" : "" } </div> 
                                        <div>LE :  { this.props.reportcard ? this.props.reportcard.user ? this.props.reportcard.user.datebirth : "" : "" } </div> 
                                    </div>
                                    <div className="">CLASSE : { this.props.reportcard ? this.props.reportcard.user ? this.props.reportcard.establishmentsectionclasse.short_entitled_class : "" : "" } { this.props.reportcard ? this.props.reportcard.user ? this.props.reportcard.establishmentsectionclasse.title : "" : "" }</div>
                                    <div className=" d-flex justify-content-between p-0">
                                        <span className=" d-inline mt-2 ml-3 mb-1">N`PERM. </span>  
                                        <table className="table d-inline  m-2 mt-2 mb-1">
                                            <tr>
                                                <td className="border px-2 " width={"40px"} height={"25px"}> </td> 
                                                <td className="border px-2 " width={"40px"} height={"25px"}> </td> 
                                                <td className="border px-2 " width={"40px"} height={"25px"}> </td> 
                                                <td className="border px-2 " width={"40px"} height={"25px"}> </td> 
                                                <td className="border px-2 " width={"40px"} height={"25px"}> </td> 
                                                <td className="border px-2 " width={"40px"} height={"25px"}> </td> 
                                                <td className="border px-2 " width={"40px"} height={"25px"}> </td> 
                                                <td className="border px-2 " width={"40px"} height={"25px"}> </td> 
                                                <td className="border px-2 " width={"40px"} height={"25px"}> </td> 
                                                <td className="border px-2 " width={"40px"} height={"25px"}> </td> 
                                                <td className="border px-2 " width={"40px"} height={"25px"}> </td> 
                                                <td className="border px-2 " width={"40px"} height={"25px"}> </td> 
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>   
                        </div>
                    </div>
                    <div className="border">
                        <div className="d-flex justify-content-between bg-light">
                            <div className="ms-4 ps-4 h5">BULLETIN DE LA { this.props.reportcard ? this.props.reportcard.user ? this.props.reportcard.establishmentsectionclasse.short_entitled_class : "" : "" } ANNEE { this.props.reportcard ? this.props.reportcard.user ? this.props.reportcard.establishmentsectionclasse.entitled_section : "" : "" } </div> 
                            <div className="me-4 h5">ANNEE SCOLAIRE { this.props.reportcard.scolary_year } </div> 
                        </div>
                    </div>

                    <div className="table-responsive border">
                        <table className="table table-striped table-sm">
                            <tr>
                                <td rowSpan={3} width="20%" className="border px-2 "><h6 className="text-center">BRANCHES</h6></td>
                                <td width="19%" colSpan={4} className="border px-2 "><h6 className="text-center fs-6">PREMIER SEMESTRE</h6></td>
                                <td width="19%" colSpan={4} className="border px-2 "><h6 className="text-center fs-6">SECOND SEMESTRE</h6></td>
                                <td rowSpan={3} width="3%" className="border px-2 "><h6 className="text-center fs-6">TOTAL GENER.</h6></td>
                                <td width="19%"  colSpan={2} className="border px-2 "><h6 className="text-center fs-6">EXAMEN DE REPECHAGE</h6></td>
                            </tr>
                            <tr>
                                <td colSpan={2} className="border px-2 "><span className="text-center fs-6">TRAV.JOUR</span></td>
                                <td rowSpan={2} className="border px-2 "><span className="text-center fs-6">EXAM.</span></td>
                                <td rowSpan={2} className="border px-2 "><span className="text-center fs-6">TOT</span></td>

                                <td colSpan={2} className="border px-2 "><span className="text-center fs-6">TRAV.JOUR</span></td>
                                <td rowSpan={2} className="border px-2 "><span className="text-center fs-6">EXAM.</span></td>
                                <td rowSpan={2} className="border px-2 "><span className="text-center fs-6">TOT</span></td>
                                
                                
                                <td rowSpan={2} className="border px-2 "><span className="text-center fs-6">%</span></td>
                                <td rowSpan={2} className="border px-2 "><span className="text-center fs-6">Signature du Professeur</span></td>
                            </tr>

                            <tr>
                                <td className="border px-2 "><span className="text-center fs-6">1e P</span></td>
                                <td className="border px-2 "><span className="text-center fs-6">2e P</span></td>

                                <td className="border px-2 "><span className="text-center fs-6">3e P</span></td>
                                <td className="border px-2 "><span className="text-center fs-6">4e P</span></td>
                            </tr>
                            
                                {
                                    this.props.reportcard && (
                                        this.props.reportcard.establishmentsectionclasse && (
                                            this.props.max.map((note) => {
                                                
                                                if(note.establishmentsectionclasse_id === this.props.reportcard.establishmentsectionclasse.id)
                                                {
                                                    return(<>
                                                        <tr key={note.id}>
                                                            <th className="border px-2">MAXIMA</th>
                                                            <th className="border">{ note.max_evaluation}</th>
                                                            <th className="border">{ note.max_evaluation}</th>
                                                            <th className="border">{ note.max_exam}</th>
                                                            <th className="border">{ parseInt(note.max_exam) +  parseInt(note.max_evaluation) +  parseInt(note.max_evaluation) }</th>
                                                            <th className="border">{ note.max_evaluation}</th>
                                                            <th className="border">{ note.max_evaluation}</th>
                                                            <th className="border">{ note.max_exam}</th>
                                                            <th className="border">{  parseInt(note.max_exam) +  parseInt(note.max_evaluation) +  parseInt(note.max_evaluation)}</th>
                                                            <th className="border">{  parseInt(note.max_exam) +  parseInt(note.max_evaluation) +  parseInt(note.max_evaluation) +  parseInt(note.max_exam) +  parseInt(note.max_evaluation) +  parseInt(note.max_evaluation)}</th>
                                                            <th className="border px-2 "></th>
                                                            <th className="border px-2 "></th>
                                                        </tr>
                                                        {
                                                            

                                                            (this.props.reportcard.reportcards ?? []).map((reportcard) => {
                                                                val = itemData.filter(item => item.id == reportcard.establishmentsectionclasselesson.lesson.id).length;
                                                                
                                                                if(val == 0 && note.max_evaluation === reportcard.establishmentsectionclasselesson.max_evaluation && note.max_exam === reportcard.establishmentsectionclasselesson.max_exam)
                                                                {
                                                                    itemData.push(reportcard.establishmentsectionclasselesson.lesson)
                                                                    newtable = this.props.reportcard.reportcards.filter((item) => item.establishmentsectionclasselesson.lesson.id == reportcard.establishmentsectionclasselesson.lesson.id);
                                                                    
                                                                    somme1 = 0;
                                                                    somme2 = 0;
                                                                    // this.props.reportcard.reportcards.splice(val, newtable.length ) //line strtegique of non diplicate
                                                                    
                                                                    return(
                                                                        <tr>
                                                                            <td className="border px-2 ">{ reportcard.establishmentsectionclasselesson.lesson.entitled_lesson}</td>
                                                                            <td className="border px-2 ">
                                                                                {
                                                                                    newtable.map((table) => {
                                                                                        if(table.periode.id == 1 && table.category == "Cours")
                                                                                        {
                                                                                            periodeT1 = periodeT1 + note.max_evaluation;
                                                                                            periode1 = periode1 + table.reportcard_note;
                                                                                            somme1 = somme1 + table.reportcard_note;
                                                                                            return (<span className={ (table.reportcard_note < (note.max_evaluation / 2)) ? "text-danger" : ""}> { table.reportcard_note } </span>)
                                                                                        }
                                                                                    })
                                                                                }
                                                                            </td>
                                                                            <td className="border px-2 ">
                                                                                {
                                                                                    newtable.map((table) => {
                                                                                        if(table.periode.id == 2 && table.category == "Cours")
                                                                                        {
                                                                                            periodeT2 = periodeT2 + note.max_evaluation;
                                                                                            periode2 = periode2 + table.reportcard_note;
                                                                                            somme1 = somme1 + table.reportcard_note;
                                                                                            return (<span className={ (table.reportcard_note < (note.max_evaluation / 2)) ? "text-danger" : ""}> { table.reportcard_note } </span>)
                                                                                        }
                                                                                    })
                                                                                }
                                                                            </td>

                                                                            <td className="border px-2 ">
                                                                                {
                                                                                    newtable.map((table) => {
                                                                                        if(table.periode.id == 1 && table.category == "Examen")
                                                                                        {
                                                                                            periodeT3 = periodeT3 + note.max_exam;
                                                                                            periode3 = periode3 + table.reportcard_note;
                                                                                            somme1 = somme1 + table.reportcard_note;
                                                                                            return (<span className={ (table.reportcard_note < (note.max_exam / 2)) ? "text-danger" : ""}> { table.reportcard_note } </span>)
                                                                                        }
                                                                                    })
                                                                                }
                                                                            </td>
                                                                            <td className="border px-2 "> 
                                                                                {
                                                                                    newtable.map((table) => {
                                                                                        if(table.periode.id == 1 && table.category == "Examen")
                                                                                        {
                                                                                            periodeT4 = periodeT1 + periodeT2 +periodeT3 ;
                                                                                            periode4 = periode1 + periode2 + periode3 ;
                                                                                            return (<span> { somme1 } </span>)
                                                                                        }
                                                                                    })
                                                                                }
                                                                            </td>

                                                                            <td className="border px-2 ">
                                                                                {
                                                                                    newtable.map((table) => {
                                                                                        if(table.periode.id == 3 && table.category == "Cours")
                                                                                        {
                                                                                            periodeT5 = periodeT5 + note.max_exam;
                                                                                            periode5 = periode5 + table.reportcard_note;
                                                                                            somme2 = somme2 + table.reportcard_note;
                                                                                            return (<span className={ (table.reportcard_note < (note.max_evaluation / 2)) ? "text-danger" : ""}> { table.reportcard_note } </span>)
                                                                                        }
                                                                                    })
                                                                                }
                                                                            </td>
                                                                            <td className="border px-2 ">
                                                                                {
                                                                                    newtable.map((table) => {
                                                                                        if(table.periode.id == 4 && table.category == "Cours")
                                                                                        {
                                                                                            periodeT6 = periodeT6 + note.max_exam;
                                                                                            periode6 = periode6 + table.reportcard_note;
                                                                                            somme2 = somme2 + table.reportcard_note;
                                                                                            return (<span className={ (table.reportcard_note < (note.max_evaluation / 2)) ? "text-danger" : ""}> { table.reportcard_note } </span>)
                                                                                        }
                                                                                    })
                                                                                }
                                                                            </td>
                                                                            <td className="border px-2 ">
                                                                                {
                                                                                    newtable.map((table) => {
                                                                                        if(table.periode.id == 2 && table.category == "Examen")
                                                                                        {
                                                                                            periodeT7 = periodeT7 + note.max_exam;
                                                                                            periode7 = periode7 + table.reportcard_note;
                                                                                            somme2 = somme2 + table.reportcard_note;
                                                                                            return (<span className={ (table.reportcard_note < (note.max_exam / 2)) ? "text-danger" : ""}> { table.reportcard_note } </span>)
                                                                                        }
                                                                                    })
                                                                                }
                                                                            </td>
                                                                                {
                                                                                    newtable.map((table) => {
                                                                                        if(table.periode.id == 2 && table.category == "Examen")
                                                                                        {
                                                                                            periodeT8 = periodeT5 + periodeT6 + periodeT7 ;
                                                                                            periode8 = periode5 + periode6 + periode7;

                                                                                            return (<>
                                                                                                <td className="border px-2 "> { somme2} </td>
                                                                                                <td className="border px-2 "> { somme2 + somme1 } </td>
                                                                                            </>)
                                                                                        }
                                                                                    })
                                                                                }
                                                                            <td className="border px-2  bg-light"></td>
                                                                            <td className="border px-2  bg-light"></td>
                                                                        </tr>
                                                                    )
                                                                }
                                                                
                                                            })
                                                        }
                                                    </>)
                                                }
                                            })
                                        )
                                    )
                                }
                                <tr key={"evive"}>
                                    <th className="border px-2">MAXIMA GENERAUX</th>
                                    <th className="border text-danger">{ periodeT1 > 0 ? periodeT1 : "" }</th>
                                    <th className="border text-danger">{ periodeT2 > 0 ? periodeT2 : "" }</th>
                                    <th className="border text-danger">{ periodeT3 > 0 ? periodeT3 : "" }</th>
                                    <th className="border text-danger">{ periodeT4 > 0 ? periodeT4 : "" }</th>
                                    <th className="border text-danger">{ periodeT5 > 0 ? periodeT5 : "" }</th>
                                    <th className="border text-danger">{ periodeT6 > 0 ? periodeT6 : "" }</th>
                                    <th className="border text-danger">{ periodeT7 > 0 ? periodeT7 : "" }</th>
                                    <th className="border text-danger">{ periodeT8 > 0 ? periodeT8 : "" }</th>
                                    <th className="border text-danger">{ periodeT8 > 0 ? periodeT4 + periodeT8 : "" }</th>
                                    <td className="border px-2 "></td>
                                    <td className="border px-2 "></td>
                                </tr>

                                <tr key={"eviveF"}>
                                    <td className="border px-2">TOTAUX</td>
                                    <td className="border ">{ periode1 > 0 ? periode1 : "" }</td>
                                    <td className="border ">{ periode2 > 0 ? periode2 : "" }</td>
                                    <td className="border ">{ periode3 > 0 ? periode3 : "" }</td>
                                    <td className="border ">{ periode4 > 0 ? periode4 : "" }</td>
                                    <td className="border ">{ periode5 > 0 ? periode5 : "" }</td>
                                    <td className="border ">{ periode6 > 0 ? periode6 : "" }</td>
                                    <td className="border ">{ periode7 > 0 ? periode7 : "" }</td>
                                    <td className="border ">{ periode8 > 0 ? periode8 : "" }</td>
                                    <td className="border ">{ periode8 > 0 ? periode4 + periode8 : "" }</td>
                                    <td className="border  bg-light"></td>
                                    <td className="border  bg-light"></td>
                                </tr>

                                <tr key={"eviveV"}>
                                    <td className="border px-2">Pourcentage</td>
                                    <td className="border ">{ periode1 > 0 ? ((periode1 * 100) / periodeT1).toFixed(1) : "" }</td>
                                    <td className="border ">{ periode2 > 0 ? ((periode2 * 100) / periodeT2).toFixed(1) : "" }</td>
                                    <td className="border ">{ periode3 > 0 ? ((periode3 * 100) / periodeT3).toFixed(1): "" }</td>
                                    <td className="border ">{ periode4 > 0 ? ((periode4 * 100) / periodeT4).toFixed(1) : "" }</td>
                                    <td className="border ">{ periode5 > 0 ? ((periode5 * 100) / periodeT5).toFixed(1) : "" }</td>
                                    <td className="border ">{ periode6 > 0 ? ((periode6 * 100) / periodeT6).toFixed(1) : "" }</td>
                                    <td className="border ">{ periode7 > 0 ? ((periode7 * 100) / periodeT7).toFixed(1) : "" }</td>
                                    <td className="border ">{ periode8 > 0 ? ((periode8 * 100) / periodeT8).toFixed(1) : "" }</td>
                                    <td className="border ">{ periode8 > 0 ?  (((periode4 + periode8) * 100) / (periodeT4 + periodeT8)).toFixed(1) : "" }</td>
                                    <td className="border  bg-light"></td>
                                    <td className="border  bg-light"></td>
                                </tr>
                                
                        </table>

                        <div className="p-2 py-4 mt-3">
                        1 L'élève  ne pourra passer dans la classe supérieure s'il n'a subi avec succès un examen de repechage en .... ... .. ..  . . . ..  .<br /> .. . . .. . .. . . .. . .. . . . . .. ...
                        </div>
                        <div className="row p-2 container-fluid">
                            <div className="col-md-7">
                                <div className="py-1">
                                    2 l'élève passe dans la classe superieure(1)
                                </div>
                                <div className="py-1">
                                    3 l'élève double sa classe(1)
                                </div>
                                <div className="py-1">
                                    2 l'élève a échoué et est à réorienter vers ... .... . . .. . . . . 
                                </div>
                            </div>
                            <div className="col-md-5">
                                Fait a Uvira le { moment(Date()). format('DD-MM-YYYY')}
                            </div>
                        </div>
                        
                    </div>
                </div>

            
            {/* <ReportcardForm
                isModal={this.state.isModal} 
                formData={this.state.formData} 
                showModal={this.showModal}
            /> */}
            

        </div>
        )
    }
}
export default Studentreportcardfirst;