import React, {Component} from "react";
import Loading from "../Loading";
import axios from "axios";
import { Link } from "react-router-dom";

class Search extends Component{
    state = {
        data : [],
        loading : false,
    }

    componentDidMount(){
        this.getData()
    }
    
    async getData(){
        var users =  await axios.get('users/search/'+this.props.match.params.search)
        if(users.status === 200)
        {
            this.setState({
                data : users.data.data,
                loading : true
            })
        }
    }
    

    render(){
        return(
            <div>
                <div className="h5 font-monospace">Rechercher "{this.props.match.params.search}"</div>
                {
                    !this.state.loading && (<Loading />)
                }
                <div className="row">
                {
                    this.state.data.map((user) => {
                        return(
                            <div className="col-md-3 col-6" key={user.id}>
                                <div className="card shadown mb-2">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0">
                                            <img src={user.avatar} alt={user.last_name} className="rounded rounded-3 overflow-hidden" style={{width:80+"px", height:80+"px"}} />
                                        </div>
                                        
                                        <div className="flex-grow-1 ms-3">
                                            <Link to={user.students.length > 0 ? "/students/"+user.student.id : user.staffs.length > 0 ? "/staffs/"+user.staff.id : "/#"} className="font-monospace text-primary text-decoration-none">{ user.matricule }</Link><br /> 
                                            <Link to={user.students.length > 0 ? "/students/"+user.student.id : user.staffs.length > 0 ? "/staffs/"+user.staff.id : "/#"} className="font-monospace text-dark text-decoration-none">{ user.last_name + " " + user.family_name  }</Link>    
                                            <Link to={user.students.length > 0 ? "/students/"+user.student.id : user.staffs.length > 0 ? "/staffs/"+user.staff.id : "/#"} className="font-monospace text-dark text-decoration-none"> {  user.first_name }</Link>    
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                </div>
            </div>
        )
    }
}
export default Search