import axios from "axios";
import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";
import FeatherIcon from 'feather-icons-react';
import Select from 'react-select';

class   EstablishmentsectionclasselessonForm extends Component{

    state = {
        id : "",
        max_exam : "",
        max_evaluation : "",
        establishmentsectionclasse_id : "",
        lesson_id : "",
        staff_id : "",
        domaine_id : "",

        errors : {},
        
        staffs : [],
        lessons : [],
        domaines : [],
        establishmentsectionclasses : [],
        isModal : false,
        title : "",
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }); 
    }

    storeLesson = () =>{
        var data = {
                establishmentsectionclasse_id : this.state.establishmentsectionclasse_id,
                lesson_id : this.state.lesson_id,
                staff_id : this.state.staff_id,
                max_exam : this.state.max_exam,
                max_evaluation : this.state.max_evaluation,
                domaine_id : this.state.domaine_id,
                operation : this.state.id ? "Editer" : "Ajouter",
            }

        var request = this.state.id ? axios.put('establishmentsectionclasselessons/'+this.state.id, data) : axios.post('establishmentsectionclasselessons', data);
        request.then((response) =>{
            this.props.showModal()
        }).catch((error) => {
            this.setState({
                errors : error.response.data.errors
            })
        })
    }

    async establishmentsectionclasses(){
        var request = await axios.get('establishmentsectionclasses');
        if(request.status === 200)
            this.setState({establishmentsectionclasses : request.data.data})
    }

    async lessons(){
        var request = await axios.get('lessons');
        if(request.status === 200)
            this.setState({lessons : request.data.data})
    }

    async domaines(){
        var request = await axios.get('domaines');
        if(request.status === 200)
            this.setState({domaines : request.data.data})
    }

    async staffs(){
        var request = await axios.get('staffs');
        if(request.status === 200)
            this.setState({staffs : request.data.data})
    }
    
    edit=()=>  {
        this.form()
        this.lessons()
        this.domaines()
        this.staffs()
        this.establishmentsectionclasses()
    }

    form=()=>{
        console.log(this.props.formData)
        this.setState({
            id: this.props.formData ? this.props.formData.id : "",
            max_exam: this.props.formData ? this.props.formData.max_exam : "",
            max_evaluation: this.props.formData ? this.props.formData.max_evaluation : "",
            establishmentsectionclasse_id: this.props.formData ? this.props.formData.establishmentsectionclasse ? this.props.formData.establishmentsectionclasse.id : "" : "",
            lesson_id: this.props.formData ? this.props.formData.lesson ? this.props.formData.lesson.id : "" : "",
            domaine_id: this.props.formData ? this.props.formData.domaine ? this.props.formData.domaine.id : "" : "",
            staff_id: this.props.formData ? this.props.formData.staff ? this.props.formData.staff.id : "" : "",
            title : this.props.formData ? "Modifier le cours" : "Ajouter le cours",
            errors : {},
        })
    }

    showModal = () => {
        this.setState({
            isModal : !this.state.isModal,
        })
    }

    render(){
        var establishmentsectionclasses = [], optioncours = [], optionstaffs = [], optiondomaines =[];
        this.state.establishmentsectionclasses.map((establishmentsectionclasse) => {
            establishmentsectionclasses.push({ value : establishmentsectionclasse.id, label : establishmentsectionclasse.classe.short_entitled_class + " "+ establishmentsectionclasse.title + " "+establishmentsectionclasse.section.short_entitled_section })
        })
        
        this.state.lessons.map((lesson) => {
            optioncours.push({ value : lesson.id, label : lesson.entitled_lesson})
        })

        this.state.domaines.map((domaine) => {
            optiondomaines.push({ value : domaine.id, label : domaine.title})
        })

        this.state.staffs.map((staff) => {
            optionstaffs.push({ value : staff.id, label : staff.user.matricule + " "+ staff.user.first_name +" "+ staff.user.last_name})
        })
        return(
            <div>
            <Modal 
                show={this.props.isModal} 
                onHide={this.props.showModal}
                size="lg"
                onEntered={this.edit}
                backdrop={"static"}>
                <Modal.Header>
                    <Modal.Title>{ this.state.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="row">

                    <div className="form-group col-md-12 mt-2">
                        <label className="font-weight-bolder">Classe</label>
                        <div className="input-group">
                            <Select className="form-control form-control-sm"
                                value = {
                                    establishmentsectionclasses.filter(option => 
                                    option.value == this.state.establishmentsectionclasse_id)
                                }
                                defaultValue={this.state.establishmentsectionclasse_id}
                                onChange={
                                    (select) => {
                                        this.setState({
                                            establishmentsectionclasse_id : select.value
                                        });
                                    }
                                }
                                options={establishmentsectionclasses}
                            />
                        </div>
                        <span className="text-danger text-small">{ this.state.errors.establishmentsectionclasse_id }</span>
                    </div>

                    <div className="form-group col-md-12 mt-2">
                        <label className="font-weight-bolder">Domaine</label>
                        <div className="input-group">
                            <Select className="form-control form-control-sm"
                                value = {
                                    optiondomaines.filter(option => 
                                    option.value == this.state.domaine_id)
                                }
                                defaultValue={this.state.domaine_id}
                                onChange={
                                    (select) => {
                                        this.setState({
                                            domaine_id : select.value
                                        });
                                    }
                                }
                                options={optiondomaines}
                            />
                        </div>
                        <span className="text-danger text-small">{ this.state.errors.domaine_id }</span>
                    </div>


                    <div className="form-group col-md-12 mt-2">
                        <label className="font-weight-bolder">Cours</label>
                        <div className="input-group">
                            <Select className="form-control form-control-sm"
                                value = {
                                    optioncours.filter(option => 
                                    option.value == this.state.lesson_id)
                                }
                                defaultValue={this.state.lesson_id}
                                onChange={
                                    (select) => {
                                        this.setState({
                                            lesson_id : select.value
                                        });
                                    }
                                }
                                options={optioncours}
                            />
                        </div>
                        <span className="text-danger text-small">{ this.state.errors.lesson_id }</span>
                    </div>

                    <div className="form-group col-md-12 mt-2">
                        <label className="font-weight-bolder">Titulaire</label>
                        <div className="input-group">
                            <Select className="form-control form-control-sm"
                                value = {
                                    optionstaffs.filter(option => 
                                    option.value == this.state.staff_id)
                                }
                                defaultValue={this.state.staff_id}
                                onChange={
                                    (select) => {
                                        this.setState({
                                            staff_id : select.value
                                        });
                                    }
                                }
                                options={optionstaffs}
                            />
                        </div>
                        <span className="text-danger text-small">{ this.state.errors.staff_id }</span>
                    </div>


                    <div className="col-6 mt-2 mt-2">
                        <div className="form-group has-icon-left">
                            <label htmlFor="nom-id-icon">Max evalution</label>
                            <div className="position-relative mt-1">
                                <input type="text" className="form-control" placeholder="Max evalution"  onChange={this.handleInput} name="max_evaluation"  value={this.state.max_evaluation} id="nom-id-icon" />
                                <div className="form-control-icon">
                                    <FeatherIcon icon="trello"></FeatherIcon>
                                </div>
                            </div>
                            <span className="text-danger text-small small">{ this.state.errors.max_evaluation }</span>
                        </div>
                    </div>

                    <div className="col-6 mt-2 mt-2">
                        <div className="form-group has-icon-left">
                            <label htmlFor="nom-id-icon">Max exam</label>
                            <div className="position-relative mt-1">
                                <input type="text" className="form-control" placeholder="Max exam"  onChange={this.handleInput} name="max_exam"  value={this.state.max_exam} id="nom-id-icon" />
                                <div className="form-control-icon">
                                    <FeatherIcon icon="box"></FeatherIcon>
                                </div>
                            </div>
                            <span className="text-danger text-small small">{ this.state.errors.max_exam }</span>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <button onClick={this.props.showModal} className="btn btn-outline-secondary btn-sm"><FeatherIcon icon="corner-down-left"></FeatherIcon> Fermer</button>
                    <button onClick={() => this.storeLesson()} className="btn btn-sm btn-outline-primary"><FeatherIcon icon="check-square"></FeatherIcon> Sauvegarder</button>
                </Modal.Footer>
            </Modal>
            
 
        </div>
        )
    }
}
export default EstablishmentsectionclasselessonForm;