import axios from "axios";
import React, {Component} from "react";
import ScheduleForm from "./ScheduleForm";
import DataSchendule from "./DataSchendule";
import Select from 'react-select';
import Loading from "../../Loading";

class   Schedule extends Component{

    state = {
        isModal : false,
        formData : null,
        category : "",
        class : "",
        schedules : [],
        laoding : false,
    }

    componentDidMount(){
        this.getSchedules();
    }

    async getSchedules(){
        var schedules = await axios.get('schedules');
        if(schedules.status === 200)
        {
            this.setState({schedules : schedules.data.data, laoding : true}) 
        }
    }

    showModal = (schedule = null) => {
        this.setState({
            class : schedule ? schedule.id ? schedule.establishmentsectionclasselesson.establishmentsectionclasse.id : schedule : "",
            isModal : !this.state.isModal,
            formData : schedule ? schedule.id ? schedule : null : null,
        })
        this.getSchedules()
    }

    delete = (schedule) =>{
        axios.delete("schedules/" + schedule).then((response) => {
            this.getSchedules()
        }).catch((error)=>{})
    }
    handleCheck(val) {
        var user = JSON.parse(localStorage.getItem("user"));
        return user ? user.permissions ? (user.permissions ?? []).length > 0 ? user.permissions.some(item => val === item.permission) : false : false : false;
    }

    render(){
        var schedules =
        this.state.schedules.map((schedule) => {
            return(
                <DataSchendule schedule={schedule} category={this.state.category} showModal={this.showModal} key={schedule.id} />
            )
        })
        var optionSchendules = [
            {
                value : "",
                label : "Categorie d'horaire",
            },
            {
                value : "Cours",
                label : "Cours",
            },
            {
                value : "Examen",
                label : "Examen",
            },
            {
                value : "Deuxieme session",
                label : "Deuxieme session",
            }
        ]
        return(
            <div>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h4 className="h4 font-monospace">Horaire</h4>
                <div className="">
                    <Select className="form-control form-control-sm"
                        value = {
                            optionSchendules.filter(option => 
                            option.value === this.state.category)
                        }
                        defaultValue={this.state.category}
                        onChange={
                            (select) => {
                                this.setState({
                                    category : select.value
                                });
                            }
                        }
                        options={optionSchendules}
                    />
                </div>
                
            </div>

            <div className="row">
                <div className="col-md-12">
                    
                    <ul className="widget-todo-list-wrapper p-0">
                        { this.state.laoding ?  schedules : <Loading /> }
                    </ul>
                </div>
            </div>
            
            <ScheduleForm 
                isModal={this.state.isModal} 
                formData={this.state.formData} 
                class = {this.state.class}
                showModal={this.showModal}
            />
            

        </div>
        )
    }
}
export default Schedule;