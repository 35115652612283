import React from "react";
import ReactLoading from "react-loading";

export default function Loading() {
return (
	<div  className="d-flex justify-content-center pt-4" >
	<ReactLoading type="balls" color="#0000FF"
		height={200} width={80} />
	<ReactLoading type="bars" color="#0000FF"
		height={200} width={80} />
	{/* <ReactLoading type="bubbles" color="#0000FF"
		height={100} width={50} /> */}
	{/* <ReactLoading type="cubes" color="#0000FF"
		height={100} width={50} /> */}
	{/* <ReactLoading type="cylon" color="#0000FF"
		height={100} width={50} />  */}
	{/* <ReactLoading type="spin" color="#0000FF"
		height={200} width={80} /> */}

 {/* <ReactLoading type="spokes" color="#0000FF"
		height={200} width={80} /> */}
	{/*<ReactLoading
		type="spinningBubbles"
		color="#0000FF"
		height={100}
		width={50}
	/> */}
	</div>
);
}
