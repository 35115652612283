import axios from "axios";
import React, {Component} from "react";
import EstablishmentsectionclasselessonForm from "./EstablishmentsectionclasselessonForm";
import FeatherIcon from 'feather-icons-react';
import Lesson from "../lessons/Lesson";
import Loading from "../../Loading";

class   Establishmentsectionclasselesson extends Component{

    state = {
        isModal : false,
        loading : false,
        formData : null,
        establishmentsectionclasses : [],
    }

    componentDidMount(){
        this.getEstablishmentsectionclasses();
    }

    async getEstablishmentsectionclasses(){
        var establishmentsectionclasses = await axios.get('establishmentsectionclasses');
        if(establishmentsectionclasses.status === 200)
        {
            this.setState({establishmentsectionclasses : establishmentsectionclasses.data.data, loading : true}) 
        }
    }

    showModal = (establishmentsectionclasse = null) => {
        this.setState({
            isModal : !this.state.isModal,
            formData : establishmentsectionclasse,
        })
        this.getEstablishmentsectionclasses()
    }

    delete = (establishmentsectionclasse) =>{
        axios.delete("lessons/" + establishmentsectionclasse).then((response) => {
            this.getEstablishmentsectionclasses()
        }).catch((error)=>{})
    }

    handleCheck(val) {
        var user = JSON.parse(localStorage.getItem("user"));
        return user ? user.permissions ? (user.permissions ?? []).length > 0 ? user.permissions.some(item => val === item.permission) : false : false : false;
      }
    
    render(){
        var establishmentsectionclasses =
        this.state.establishmentsectionclasses.map((establishmentsectionclasse) => {
            return(
                <li className="widget-todo-item list-group-item m-2 border rounded-3" key={establishmentsectionclasse.id}>
                    <div
                        className="widget-todo-title-wrapper d-flex justify-content-between align-items-center mb-50">
                        <div className="widget-todo-title-area d-flex align-items-center">
                            <FeatherIcon icon="box" className='cursor-move'></FeatherIcon>
                            <label htmlFor={establishmentsectionclasse.id}><span className="widget-todo-title ml-50">{establishmentsectionclasse.classe.short_entitled_class} {establishmentsectionclasse.classe.entitled_class} <span className="text-primary">{establishmentsectionclasse.title}</span> </span></label>
                        </div>
                        <div className="widget-todo-item-action d-flex align-items-center">
                            {establishmentsectionclasse.section.short_entitled_section} <FeatherIcon icon="list" size="16" className='cursor-move text-dark'></FeatherIcon>
                        </div>
                    </div>
                    <div>
                        <ul className="widget-todo-list-wrapper p-0">
                            {
                                establishmentsectionclasse.establishmentsectionclasselessons.map((establishmentsectionclasselesson) => {
                                    return(
                                        <li className="widget-todo-item list-group-item m-2 border rounded-3" key={establishmentsectionclasselesson.id}>
                                            <div
                                                className="widget-todo-title-wrapper d-flex justify-content-between align-items-center mb-50">
                                                <div className="widget-todo-title-area d-flex align-items-center">
                                                    <FeatherIcon icon="bookmark" className='cursor-move'></FeatherIcon>
                                                    <label htmlFor={establishmentsectionclasselesson.id} type="button" onClick={() => this.showModal(establishmentsectionclasselesson)}><span className="widget-todo-title ml-50">{establishmentsectionclasselesson.lesson.short_entitled_lesson} </span></label>
                                                </div>
                                                {
                                                    establishmentsectionclasselesson.staff && (
                                                        <div>
                                                            <a href={'https://evivesomrh.vercel.app/staffs/'+establishmentsectionclasselesson.staff.id}  className="text-decoration-none">
                                                                <img src={ establishmentsectionclasselesson.staff.user.avatar} title={establishmentsectionclasselesson.staff.user.matricule + " "+establishmentsectionclasselesson.staff.user.first_name} className="rounded-circle" width="30" />
                                                                {establishmentsectionclasselesson.staff.user.matricule + " "+establishmentsectionclasselesson.staff.user.first_name}
                                                            </a>
                                                        </div>
                                                        )
                                                }

                                                <div className="widget-todo-item-action d-flex align-items-center">
                                                    {establishmentsectionclasselesson.max_evaluation} <FeatherIcon icon="grid" size="16" className='d-inline text-primary mx-1'></FeatherIcon>{establishmentsectionclasselesson.max_exam}
                                                    {
                                                        this.handleCheck("Modifier cours") == true && (
                                                          <FeatherIcon icon="edit-2" size="16" className='cursor-move text-primary' type="button" onClick={() => this.showModal(establishmentsectionclasselesson)}></FeatherIcon>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </li>
            )
        })
        return(
            <div>

            <div className="row">
                <div className="col-md-8">
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                        <h4 className="h4 font-monospace">Cours par classe</h4>
                        {
                            this.handleCheck("Ajouter cours") == true && (
                                <div className="btn-toolbar mb-2 mb-md-0">
                                    <div className="btn-group me-2">
                                        <button type="button" className="btn btn-sm btn-outline-primary" onClick={() => this.showModal(null)}><FeatherIcon icon="plus-circle" ></FeatherIcon></button>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    <ul className="widget-todo-list-wrapper p-0">
                        { this.state.loading ?  establishmentsectionclasses  : <Loading />}
                    </ul>
                </div>
                {
                    this.handleCheck("Afficher type de cours") == true && (
                        <div className="col-md-4">
                            <div className="card shadow mb-2">
                                <div className="card-body">
                                    <Lesson />
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
            
            <EstablishmentsectionclasselessonForm 
                isModal={this.state.isModal} 
                formData={this.state.formData} 
                showModal={this.showModal}
            />
            

        </div>
        )
    }
}
export default Establishmentsectionclasselesson;