import axios from "axios";
import React, {Component} from "react";
import { Link } from "react-router-dom";
import Listprint from "../../Listprint";
import Loading from "../../Loading";
import ReportLine from "./ReportLine";

class Report extends Component{

    state = {
        loading : false,
        columns : [
            {
                label: 'Matr',
                field: 'matricule',
                sort: 'asc',
                width: 50
            },
            {
                label: 'Eleve',
                field: 'student',
                sort: 'asc',
                width: 200
            },
            {
                label: 'Pourcent',
                field: 'studentsuccess',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Classe',
                field: 'classe',
                sort: 'asc',
                width: 120
            },
            {
                label: 'Section',
                field: 'section',
                sort: 'asc',
                width: 120
            },
            {
                label: 'Date',
                field: 'created_at',
                sort: 'asc',
                width: 120
            },
        ],
        rows : [],
        establishmentsectionclasses : []
    }

    componentDidMount(){
        this.getStudents();
        this.getEstablishmentsectionclasses()
    }

    async getStudents(){
      var students = await axios.get('students/all');

      if(students.status === 200)
      {
          var  rows = [];
          students.data.data.map((student) => {
              rows.push({
                matricule : student.user && (<Link to={'/students/'+student.id}  className="text-decoration-none text-primary">{student.user.matricule}</Link>),
                student : student.user && (student.user.last_name +" "+ student.user.family_name  +" "+ student.user.first_name ),
                studentsuccess : student.studentmax > 0 ? ((student.studentsuccess * 100 ) / student.studentmax).toFixed(1)+"%" : "0%",
                classe : student.class.short_entitled_class + " " + student.class.title,
                section : student.class.short_entitled_section,
                created_at : student.created_at,
               });
          })

          this.setState({
               rows : rows,
               loading : true
          })
          
      }
    }

    async getEstablishmentsectionclasses(){
        var establishmentsectionclasses = await axios.get('establishmentsectionclasses');
        if(establishmentsectionclasses.status === 200)
        {
            this.setState({establishmentsectionclasses : establishmentsectionclasses.data.data, loading : true}) 
        }
    }

    render(){
        var   max=0, note=0, labels = [], data = [];
        this.state.establishmentsectionclasses.map((establishmentsectionclasse) => {
            max=0;
            note=0;
            labels.push(establishmentsectionclasse.classe.short_entitled_class + " " +establishmentsectionclasse.title + " " +establishmentsectionclasse.section.short_entitled_section)
            establishmentsectionclasse.students.filter(student => student.operation != "Archiver" && student.operation != "Supprimer").map((student) => {
                note += student.studentsuccess
                max += student.studentmax
            })
            data.push(max > 0 ? ((note * 100 ) / max).toFixed(1) : 0 )
        })
        
        return(
            <div>
                {/* <Helmet>
                    <title>EviveSoma | reports student records</title>
                </Helmet> */}
                <div className=" align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h4 className="h4 font-monospace">Rapport des élèves par record des points</h4>
                </div>
                {
                    !this.state.loading && (<Loading />)
                }

                {
                    labels.length > 0 && (
                    <ReportLine
                        labels={labels}
                        data={data}
                        title={
                            "Statistique des resultats par classe " 
                        }
                        />
                    )
                }

                {
                    this.state.rows.length > 0 && (
                    <Listprint columns={this.state.columns} rows={this.state.rows} title={"Rapport des élève par record des points"}/>
                    )
                } 
                

            </div>
        )
    }
}
export default Report