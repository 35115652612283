import axios from "axios";
import React, {Component} from "react";
import FeatherIcon from 'feather-icons-react';
import Loading from "../../Loading";
import { Link } from "react-router-dom";

class   Note extends Component{

    state = {
        isModal : false,
        formData : null,
        loading : false,
        evaluation : null,
        establishmentsectionclasse : null,
        note : [],
        errors : {},
    }

    componentDidMount(){
        this.getNotes();
    }

    async getNotes(){
        var evaluation = await axios.get('evaluations/' + this.props.match.params.evaluation + "/edit");
        if(evaluation.status === 200)
        {
            this.setState({evaluation : evaluation.data.data}) 
            this.getStudents();
        }
    }

    async getStudents(){
        var establishmentsectionclasse = await axios.get('establishmentsectionclasses/' + this.state.evaluation.schedule.establishmentsectionclasselesson.establishmentsectionclasse.id);
        if(establishmentsectionclasse.status === 200)
        {
            this.setState({establishmentsectionclasse : establishmentsectionclasse.data.data, loading : true}) 
        }
    }

    handleInput = (e) => {
         
        this.state.note[e.target.name] = e.target.value;
    }

    async storeNote() {
        this.setState({ establishmentsectionclasse : null, loading : false})
        var note = [];
        this.state.establishmentsectionclasse.students.map((student) => {
            if(this.state.note[student.id] >= 0)
            {
                note.push(student.id)
                note.push(this.state.note[student.id])
                this.state.note[student.id] = ""
            }
        })
        
        var data = {
            student : 1,
            evaluation : this.state.evaluation.id,
            note : note,
            operation : this.state.id ? "Editer" : "Ajouter",
        };
        
        var request = await axios.post('notes', data);
        this.getNotes()
    }
    
    handleCheck(val) {
        var user = JSON.parse(localStorage.getItem("user"));
        return user ? user.permissions ? (user.permissions ?? []).length > 0 ? user.permissions.some(item => val === item.permission) : false : false : false;
    }

    render(){
        var students = "", noteM = 0;
        
        this.state.establishmentsectionclasse && (
            this.state.establishmentsectionclasse.students &&(
                students = 
                // this.state.establishmentsectionclasse.students.filter(student => student.operation === "Ajouter" && student.scolary_year === this.state.evaluation.schedule.scolary_year || student.operation === "Editer" && student.scolary_year === this.state.evaluation.schedule.scolary_year).map((student) => {
                    this.state.establishmentsectionclasse.students.map((student) => {
                        noteM = 0
                    return(
                        <li className="widget-todo-item list-group-item m-2 border rounded-3" key={student.id}>
                            <div
                                className="widget-todo-title-wrapper d-flex justify-content-between align-items-center mb-50">
                                <div className="widget-todo-title-area d-flex align-items-center">
                                    <img src={ student.user.avatar }  className="rounded img-fluid" width={30} />
                                    <label htmlFor={student.id} className={student.operation == "Archiver" ? "text-success" : student.operation == "Supprimer" ? "text-danger" : "" }><span className="widget-todo-title ml-50">{ student.user.matricule } { student.user.last_name } { student.user.family_name } { student.user.first_name }</span>
                                    <small className="small"> { student.scolary_year }</small>
                                        {
                                            student.reportcards && (
                                                student.reportcards.map((reportcard) => {
                                                    return(
                                                        reportcard.notes.filter(note =>note.evaluation_id === this.state.evaluation.id).map((note) => {
                                                            noteM = this.state.evaluation.max
                                                            return (<span className="text-danger"> { note.note }/{this.state.evaluation.max}</span>)
                                                        })
                                                    )
                                                })
                                            )
                                        }
                                    </label>
                                </div>
                                
                                <div className="widget-todo-item-action d-flex align-items-center">
                                    {
                                        student.operation == "Ajouter" && student.scolary_year == this.state.evaluation.schedule.scolary_year &&(
                                            this.handleCheck("Ajouter note") == true && noteM == 0 || this.handleCheck("Modifier note") == true && noteM > 0  ?(
                                                <input type="number" className="form-control form-control-sm"  name={ student.id } value={ this.state.note[student.id] ? this.state.note[student.id] : null } onChange={this.handleInput}  />
                                            ) : ""
                                        )
                                    }
                                    {
                                        student.operation == "Editer" && student.scolary_year == this.state.evaluation.schedule.scolary_year &&(
                                            this.handleCheck("Ajouter note") == true && noteM == 0 || this.handleCheck("Modifier note") == true && noteM > 0  ?(
                                                <input type="number" className="form-control form-control-sm"  name={ student.id } value={ this.state.note[student.id] ? this.state.note[student.id] : null } onChange={this.handleInput}  />
                                            ) : ""
                                        )
                                    }
                                </div>
                            </div>
                        </li>
                    )
                })
            )
        )
        return(
            <div>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h4 className="h4 font-monospace">Note { this.state.evaluation ? this.state.evaluation.category : ""} </h4>
            </div>

            <div className=" mb-4 overflow-auto">
                 
                <div className="">
                    {
                        this.state.evaluation && (
                            <div
                                className="widget-todo-title-wrapper card shadow  text-center align-items-center mb-3">
                                <div className="widget-todo-title-area  align-items-center">
                                    <FeatherIcon icon="cloud-drizzle" className='cursor-move'></FeatherIcon>
                                    <label htmlFor={this.state.evaluation.id}><span className="widget-todo-title ml-50">{ this.state.evaluation.schedule.schedulecategory.category  + " "+ this.state.evaluation.schedule.establishmentsectionclasselesson.establishmentsectionclasse.short_entitled_class + " "+ this.state.evaluation.schedule.establishmentsectionclasselesson.establishmentsectionclasse.title + " "+ this.state.evaluation.schedule.establishmentsectionclasselesson.establishmentsectionclasse.short_entitled_section}  </span></label>
                                    <div className="fw-bold text-center">{this.state.evaluation.schedule.establishmentsectionclasselesson.lesson.short_entitled_lesson } / <span className="text-danger">{ this.state.evaluation.max }</span></div>
                                    <div className="text-center fst-italic fw-lighter"> {this.state.evaluation.reason_evaluation} </div>
                                    <div className="text-end fst-italic" style={{ fontSize : 11 + "px"}}>{(this.state.evaluation.schedule.date_start ? this.state.evaluation.schedule.date_start : (this.state.evaluation.created_at)) } # { this.state.evaluation.schedule.scolary_year  }</div>
                                </div>
                                {/* <div className="widget-todo-item-action text-end">
                                    <FeatherIcon icon="edit" size="20" className='cursor-move text-white bg-primary m-1 p-1 rounded rounded-circle' type="button" onClick={() => this.showModal(this.state.evaluation)}></FeatherIcon>
                                </div> */}
                            </div>
                        )
                    }
                    

                    <ul className="widget-todo-list-wrapper p-0">
                        <li className="widget-todo-item list-group-item m-2 border-0 rounded-3" key="12">
                            <div
                                className="widget-todo-title-wrapper d-flex justify-content-between align-items-center mb-50">
                                <div className="widget-todo-title-area d-flex align-items-center fw-bold">
                                    Elèves
                                </div>
                                
                                <div className="widget-todo-item-action d-flex align-items-center fw-bold">
                                    Points
                                </div>
                            </div>
                        </li>
                        { this.state.loading ? students : <Loading /> }
                    </ul>
                    <div className="text-end">
                        <Link to="/evaluations" className="btn btn-sm btn-outline-secondary"><FeatherIcon icon="corner-down-left"></FeatherIcon> Retour</Link>
                        <button onClick={() => this.storeNote()} className="btn btn-sm btn-outline-primary"><FeatherIcon icon="check-square"></FeatherIcon> Sauvegarder</button>
                    </div>
                </div>
            </div>
            
            

        </div>
        )
    }
}
export default Note;