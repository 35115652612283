import axios from "axios";
import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";
import FeatherIcon from 'feather-icons-react';
import Select from 'react-select';

class   ScheduleForm extends Component{

    state = {
        id : null,
        lesson : null,
        staff : null,
        day : null,
        schedulecategory : null,
        periode : null,

        date_start : null,
        date_end : null,
        time_start : null,
        time_end : null,
        scolary_year : null,

        errors : {},
        
        staffs : [],
        establishmentsectionclasselessons : [], //lesson
        days : [],
        schedulecategories : [],
        periodes : [],
        years : [],
        sms : 1,

        isModal : false,
        title : null,
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }); 
    }

    async getYear(){
        var data=[], years = await axios.get('years');
        if(years.status === 200)
        {
            years.data.data.map((year) => {
                data.push({value : year.scolary_year, label : year.scolary_year})
            })
            
            this.setState({
                years : data
            })
        }
    }

    storeLesson = () =>{
        var data = {
                lesson : this.state.lesson,
                staff : this.state.staff,
                day : this.state.day,
                schedulecategory : this.state.schedulecategory,
                periode : this.state.periode,
                date_start : this.state.date_start,
                date_end : this.state.date_end,
                time_start : this.state.time_start,
                time_end : this.state.time_end,
                scolary_year : this.state.scolary_year,
            }

        var request = this.state.id ? axios.put('schedules/'+this.state.id, data) : axios.post('schedules', data);
        request.then((response) =>{
            this.props.showModal()
        }).catch((error) => {
            this.setState({
                errors : error.response.data.errors
            })
        })
    }

    async establishmentsectionclasselessons(){
        var request = await axios.get('establishmentsectionclasselessons');
        if(request.status === 200)
            this.setState({establishmentsectionclasselessons : request.data.data})
    }

    async staffs(){
        var request = await axios.get('staffs');
        if(request.status === 200)
            this.setState({staffs : request.data.data})
    }

    async days(){
        var request = await axios.get('days');
        if(request.status === 200)
            this.setState({days : request.data.data})
    }

    async schedulecategories(){
        var request = await axios.get('schedulecategories');
        if(request.status === 200)
            this.setState({schedulecategories : request.data.data})
    }

    async periodes(){
        var request = await axios.get('periodes');
        if(request.status === 200)
            this.setState({periodes : request.data.data})
    }   
    
    edit=()=>  {
        this.form()
        this.staffs()
        this.establishmentsectionclasselessons()
        this.days()
        this.schedulecategories()
        this.periodes()
        this.getYear()
    }

    form=()=>{
        this.setState({
            id: this.props.formData ? this.props.formData.id : "",
            max_exam: this.props.formData ? this.props.formData.max_exam : "",
            max_evaluation: this.props.formData ? this.props.formData.max_evaluation : "",
            lesson: this.props.formData ? this.props.formData.establishmentsectionclasselesson ? this.props.formData.establishmentsectionclasselesson.id : "" : "",
            staff: this.props.formData ? this.props.formData.staff ? this.props.formData.staff.id : "" : "",
            periode: this.props.formData ? this.props.formData.periode ? this.props.formData.periode.id : "" : "",
            schedulecategory: this.props.formData ? this.props.formData.schedulecategory ? this.props.formData.schedulecategory.id : "" : "",
            day: this.props.formData ? this.props.formData.establishmentsectionclasseday ? this.props.formData.establishmentsectionclasseday.id : "" : "",
            date_start : this.props.formData? this.props.formData.date_start : "",
            date_end : this.props.formData? this.props.formData.date_end : "",
            time_start : this.props.formData? this.props.formData.time_start : "",
            time_end : this.props.formData? this.props.formData.time_end : "",
            scolary_year : this.props.formData ? this.props.formData.scolary_year : "",
            title : this.props.formData ? "Modifier la planification de l'horaire" : "Planification de l'horaire",
            errors : {},
        })
    }

    showModal = () => {
        this.setState({
            isModal : !this.state.isModal,
        })
    }

    handleCheck(val) {
        var user = JSON.parse(localStorage.getItem("user"));
        return user ? user.permissions ? (user.permissions ?? []).length > 0 ? user.permissions.some(item => val === item.permission) : false : false : false;
    }

    sms = (schoolfee) => {
        axios.get('schoolfees/sms/'+schoolfee).then((response) => {
            this.showModal()
        }).catch((error) => {
            this.setState({
                errors : {
                    sms : "sms non envoyé"
                }
            })
        })
    }

    render(){
        var optionestablishmentsectionclasselessons = [], optionstaffs = [], optiondays = [], optionschedulecategories = [], optionperiodes = [];
        
        this.state.establishmentsectionclasselessons.map((establishmentsectionclasselesson) => {
            if(this.props.class == establishmentsectionclasselesson.establishmentsectionclasse.id)
            {
                optionestablishmentsectionclasselessons.push({ value : establishmentsectionclasselesson.id, label : establishmentsectionclasselesson.lesson.entitled_lesson + " "+ establishmentsectionclasselesson.establishmentsectionclasse.short_entitled_class + " "+ establishmentsectionclasselesson.establishmentsectionclasse.title + " / " + establishmentsectionclasselesson.establishmentsectionclasse.short_entitled_section , staff : establishmentsectionclasselesson.staff.id })
            }
        })

        this.state.staffs.map((staff) => {
            optionstaffs.push({ value : staff.id, label : staff.user.first_name + " "+staff.user.last_name + " "+staff.user.matricule})
        })

        this.state.days.map((day) => {
            optiondays.push({ value : day.id, label : day.day })
        })

        this.state.schedulecategories.map((category) => {
            optionschedulecategories.push({ value : category.id, label : category.category })
        })

        this.state.periodes.map((periode) => {
            optionperiodes.push({ value : periode.id, label : periode.periode })
        })

        var optionsms = [];
        optionsms.push({
            value : 1, label:"Oui"
        })
        optionsms.push({
            value : 0, label:"Non"
        })

        return(
            <div>
                <Modal 
                    show={this.props.isModal} 
                    onHide={this.props.showModal}
                    size="lg"
                    onEntered={this.edit}
                    backdrop={"static"}>
                    <Modal.Header>
                        <Modal.Title>{ this.state.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="row">

                        <div className="form-group col-md-4 mt-2">
                            <label className="font-weight-bolder">Categorie</label>
                            <div className="input-group">
                                <Select className="form-control form-control-sm"
                                    value = {
                                        optionschedulecategories.filter(option => 
                                        option.value === this.state.schedulecategory)
                                    }
                                    defaultValue={this.state.schedulecategory}
                                    onChange={
                                        (select) => {
                                            this.setState({
                                                schedulecategory : select.value
                                            });
                                        }
                                    }
                                    options={optionschedulecategories}
                                />
                            </div>
                            <span className="text-danger text-small">{ this.state.errors.schedulecategory }</span>
                        </div>

                        <div className="form-group col-md-8 mt-2">
                            <label className="font-weight-bolder">Cours et classe</label>
                            <div className="input-group">
                                <Select className="form-control form-control-sm"
                                    value = {
                                        optionestablishmentsectionclasselessons.filter(option => 
                                        option.value === this.state.lesson)
                                    }
                                    defaultValue={this.state.lesson}
                                    onChange={
                                        (select) => {
                                            this.setState({
                                                lesson : select.value,
                                                staff : select.staff
                                            });
                                        }
                                    }
                                    options={optionestablishmentsectionclasselessons}
                                />
                            </div>
                            <span className="text-danger text-small">{ this.state.errors.lesson }</span>
                        </div>

                        <div className="form-group col-md-12 mt-2">
                            <label className="font-weight-bolder">Tutilaire</label>
                            <div className="input-group">
                                <Select className="form-control form-control-sm"
                                    value = {
                                        optionstaffs.filter(option => 
                                        option.value === this.state.staff)
                                    }
                                    defaultValue={this.state.staff}
                                    onChange={
                                        (select) => {
                                            this.setState({
                                                staff : select.value
                                            });
                                        }
                                    }
                                    options={optionstaffs}
                                />
                            </div>
                            <span className="text-danger text-small">{ this.state.errors.staff }</span>
                        </div>

                        <div className="form-group col-md-6 mt-2">
                            <label className="font-weight-bolder">Periode</label>
                            <div className="input-group">
                                <Select className="form-control form-control-sm"
                                    value = {
                                        optionperiodes.filter(option => 
                                        option.value === this.state.periode)
                                    }
                                    defaultValue={this.state.periode}
                                    onChange={
                                        (select) => {
                                            this.setState({
                                                periode : select.value
                                            });
                                        }
                                    }
                                    options={optionperiodes}
                                />
                            </div>
                            <span className="text-danger text-small">{ this.state.errors.periode }</span>
                        </div>

                        <div className="form-group col-md-6 mt-2">
                            <label className="font-weight-bolder">Jour</label>
                            <div className="input-group">
                                <Select className="form-control form-control-sm"
                                    value = {
                                        optiondays.filter(option => 
                                        option.value === this.state.day)
                                    }
                                    defaultValue={this.state.day}
                                    onChange={
                                        (select) => {
                                            this.setState({
                                                day : select.value
                                            });
                                        }
                                    }
                                    options={optiondays}
                                />
                            </div>
                            <span className="text-danger text-small">{ this.state.errors.day }</span>
                        </div>

                        <div className="col-md-8 mt-2 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Date debut</label>
                                <div className="position-relative mt-1">
                                    <input type="date" className="form-control"   onChange={this.handleInput} name="date_start"  value={this.state.date_start} id="nom-id-icon" />
                                    <div className="form-control-icon">
                                        <FeatherIcon icon="clock"></FeatherIcon>
                                    </div>
                                </div>
                                <span className="text-danger text-small small">{ this.state.errors.date_start }</span>
                            </div>
                        </div>

                        <div className="col-md-4 mt-2 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Heure debut</label>
                                <div className="position-relative mt-1">
                                    <input type="time" className="form-control"   onChange={this.handleInput} name="time_start"  value={this.state.time_start} id="nom-id-icon" />
                                    <div className="form-control-icon">
                                        <FeatherIcon icon="watch"></FeatherIcon>
                                    </div>
                                </div>
                                <span className="text-danger text-small small">{ this.state.errors.time_start }</span>
                            </div>
                        </div>

                        <div className="col-md-8 mt-2 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Date cloture</label>
                                <div className="position-relative mt-1">
                                    <input type="date" className="form-control"   onChange={this.handleInput} name="date_end"  value={this.state.date_end} id="nom-id-icon" />
                                    <div className="form-control-icon">
                                        <FeatherIcon icon="clock"></FeatherIcon>
                                    </div>
                                </div>
                                <span className="text-danger text-small small">{ this.state.errors.date_end }</span>
                            </div>
                        </div>

                        <div className="col-md-4 mt-2 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Heure cloture</label>
                                <div className="position-relative mt-1">
                                    <input type="time" className="form-control"   onChange={this.handleInput} name="time_end"  value={this.state.time_end} id="nom-id-icon" />
                                    <div className="form-control-icon">
                                        <FeatherIcon icon="watch"></FeatherIcon>
                                    </div>
                                </div>
                                <span className="text-danger text-small small">{ this.state.errors.time_end }</span>
                            </div>
                        </div>

                        <div className=" col-6 mt-2">
                            <label className="font-monospace fw-normal">Année scolaire</label>
                            <div className="input-group">
                                <Select
                                    className="form-control"
                                    value = {
                                        this.state.years.filter(option => 
                                        option.value == this.state.scolary_year)
                                    }
                                    defaultValue={this.state.scolary_year}
                                    onChange={
                                        (select) => {
                                            this.setState({
                                                scolary_year : select.value
                                            });
                                        }
                                    }
                                    options={this.state.years}
                                />
                            </div>
                            <span className="text-danger text-small">{ this.state.errors.scolary_year }</span>
                        </div>

                        <div className="form-group col-md-4 mt-2">
                            <label className="font-monospace fw-normal">Notifier par sms</label>
                            <div className="input-group">
                                <Select 
                                    className="form-control form-control-small"
                                        value = {
                                            optionsms.filter(option => 
                                            option.value == this.state.sms)
                                        }
                                        defaultValue={this.state.sms}
                                        onChange={
                                            (select) => {
                                                this.setState({
                                                    sms : select.value
                                                });
                                            }
                                        }
                                        options={optionsms}
                                    />
                                {
                                    this.state.id && (
                                        <button className="btn btn-outline-primary" type="button" id="button-addon2" onClick={() => this.sms(this.state.id)}>{this.props.formData ? this.props.formData.notifiant > 0 ? this.props.formData.notifiant  : "0" : "0"}<FeatherIcon icon="mail" ></FeatherIcon></button>
                                    )
                                }
                            </div>
                            <span className="text-danger text-small">{ this.state.errors.sms }</span>
                        </div>
                         

                    </Modal.Body>
                    <Modal.Footer>
                        <button onClick={this.props.showModal} className="btn btn-outline-secondary btn-sm"><FeatherIcon icon="corner-down-left"></FeatherIcon> Fermer</button>
                        {
                            this.handleCheck("Ajouter horaire") == true || this.handleCheck("Modifier horaire") == true ? (
                                <button onClick={() => this.storeLesson()} className="btn btn-sm btn-outline-primary"><FeatherIcon icon="check-square"></FeatherIcon> Sauvegarder</button>
                            ) : ""
                        }
                        </Modal.Footer>
                </Modal>
            </div>
        )
    }
}
export default ScheduleForm;