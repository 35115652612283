import axios from "axios";
import React, {Component} from "react";
import QuestionForm from "./QuestionForm";
import FeatherIcon from 'feather-icons-react';

class   Question extends Component{

    state = {
        isModal : false,
        formData : null,
        evaluation : null,
    }

    componentDidMount(){
        this.getQuestions();
    }

    async getQuestions(){
        
        var evaluation = await axios.get('evaluations/' + this.props.match.params.evaluation + "/edit");
        if(evaluation.status === 200)
        {
            this.setState({evaluation : evaluation.data.data}) 
        }
    }

    showModal = (question = null) => {
        this.setState({
            isModal : !this.state.isModal,
            formData : question ? question : { evaluation_id : this.props.match.params.evaluation},
        })
        this.getQuestions()
    }

    delete = (question) =>{
        axios.delete("questions/" + question).then((response) => {
            this.getQuestions()
        }).catch((error)=>{})
    }

    render(){
        var questions = "";
        this.state.evaluation && (
            questions = 
            this.state.evaluation.questions.map((question) => {
                return(
                    <li className="widget-todo-item list-group-item m-2 border rounded-3" key={question.id}>
                        <div
                            className="widget-todo-title-wrapper d-flex justify-content-between align-items-center mb-50">
                            <div className="widget-todo-title-area d-flex align-items-center">
                                <FeatherIcon icon="box" className='cursor-move'></FeatherIcon>
                                <label htmlFor={question.id}><span className="widget-todo-title ml-50">{question.question}  </span></label>
                            </div>
                            <div className="text-end">
                                {
                                    question.answers.map((answer) => {
                                        return(
                                            <label className="text-sm sm fst-italic" style={{ fontSize : 11 + "px"}} key={answer.id}> {answer.answer} </label>
                                        )
                                    })
                                }
                            </div>
                            <div className="widget-todo-item-action d-flex align-items-center">
                                <FeatherIcon icon="edit-2" size="16" className='cursor-move text-primary' type="button" onClick={() => this.showModal(question)}></FeatherIcon>
                            </div>
                        </div>
                    </li>
                )
            })
        )
        return(
            <div>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h4 className="h4 font-monospace">Question de l'evaluation </h4>
            </div>

            <div className=" mb-4 overflow-auto">
                 
                <div className="">
                    {
                        this.state.evaluation && (
                            <div
                                className="widget-todo-title-wrapper card shadow  text-center align-items-center mb-3">
                                <div className="widget-todo-title-area  align-items-center">
                                    <FeatherIcon icon="box" className='cursor-move'></FeatherIcon>
                                    <label htmlFor={this.state.evaluation.id}><span className="widget-todo-title ml-50">{ this.state.evaluation.schedule.schedulecategory.category  + " "+ this.state.evaluation.schedule.establishmentsectionclasselesson.establishmentsectionclasse.short_entitled_class + " "+ this.state.evaluation.schedule.establishmentsectionclasselesson.establishmentsectionclasse.title + " "+ this.state.evaluation.schedule.establishmentsectionclasselesson.establishmentsectionclasse.short_entitled_section}  </span></label>
                                    <div className="fw-bold text-center">{this.state.evaluation.schedule.establishmentsectionclasselesson.lesson.short_entitled_lesson }  / <span className="text-danger">{ this.state.evaluation.max }</span></div>
                                    <div className="text-center fst-italic fw-lighter"> {this.state.evaluation.reason_evaluation} </div>
                                    <div className="text-end fst-italic" style={{ fontSize : 11 + "px"}}>{(this.state.evaluation.created_at) }</div>
                                </div>
                                {/* <div className="widget-todo-item-action text-end">
                                    <FeatherIcon icon="edit" size="20" className='cursor-move text-white bg-primary m-1 p-1 rounded rounded-circle' type="button" onClick={() => this.showModal(this.state.evaluation)}></FeatherIcon>
                                </div> */}
                            </div>
                        )
                    }
                    
                    <div className="text-end">
                        <div className="btn-group me-2">
                            <button type="button" className="btn btn-sm btn-outline-primary" onClick={() => this.showModal(null)}><FeatherIcon icon="plus-circle" ></FeatherIcon> Question</button>
                        </div>
                    </div>
                    <ul className="widget-todo-list-wrapper p-0">
                        { questions }
                    </ul>
                </div>
            </div>
            
            <QuestionForm 
                isModal={this.state.isModal} 
                formData={this.state.formData} 
                showModal={this.showModal}
            />
            

        </div>
        )
    }
}
export default Question;