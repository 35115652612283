import React, { Component } from 'react';
import axios from 'axios';
import FeatherIcon from 'feather-icons-react';
import ReactLoading from "react-loading";

class Login extends Component {
    state = {
        username: "",
        password: "",
        error : "",
        click : false
    }
     componentDidMount(){
         window.localStorage.clear();
        // this.getUser()
    }
    
    async getUser(){
        if(this.props.match.params.user_id)
        {
            var user = this.props.match.params.user_id;
            var exist = await axios.get('users/get/'+user);
            if(exist.status === 200)
            {
                if(exist.data.token)
                {
                    localStorage.setItem('token', exist.data.token)
                    localStorage.setItem('user_id', exist.data.user.id)
                    window.location.replace(`https://evivesomastat.vercel.app/`)
                }
                else{
                    window.location.replace(`https://evivesoma.vercel.app/`)
                }
            }
        }
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    login  = () => {
        this.setState({
            click : true,
            error : "",
        })
         axios.post('login', {
            username: this.state.username,
            password: this.state.password
        }).then((login) => {

            localStorage.setItem("token", login.data.token);
            localStorage.setItem("user", JSON.stringify(login.data.user));
            localStorage.setItem("establishment", JSON.stringify(login.data.establishment));
            window.location.replace(`https://evivesomastat.vercel.app/`)
            
        }).catch((error) => {
            axios.post('logout').then((re) => { console.log("disconnect") }).catch((erro) => { console.log("error") });
            window.localStorage.clear();
            this.setState({
                error: error.response.data.error,
            });
            // window.location.replace(`http://.com/login`)
        })
    }

    render() {

        return (
            <div id="auth">
                {
                    !this.props.match.params.user_id && (
                        <section className="section register min-vh-50 d-flex flex-column align-items-center justify-content-center ">
                            <div className="container">
                            <div className="row justify-content-center mt-4  ">
                                <div className="col-md-5 col-sm-12 mx-auto d-flex flex-column align-items-center justify-content-center p-0">
                    
                                <div className="card mt-4 shadow mb-3 rounded-3">

                                    <div className="card-body">

                                    <div className="mt-2">
                                        <img className="d-block mx-auto" src="/logo192.png" alt="" width="100" height="80" />
                                        <h5 className="  text-center  fs-4">EviveSoma Stat</h5>
                                        {/* <div className="text-center small lead">Entrer votre Email et mot de passe pour vous authentifier</div> */}
                                    </div>

                                    <form className="row g-3 needs-validation">
                                        {this.state.error && (<div className="alert alert-danger text-center text-danger p-1 px-2 text-small">{this.state.error}</div>)}
                                        <div className="col-12">
                                            <label htmlFor="yourUsername" className="form-label">Email ou téléphone</label>
                                            <div className="input-group has-validation">
                                                <span className="input-group-text" id="inputGroupPrepend"><FeatherIcon icon={"lock"}></FeatherIcon> </span>
                                                <input type="text" placeholder="Email ou téléphone"  name="username" onChange={this.handleInput} className="form-control" id="yourUsername" />
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <label htmlFor="yourPassword" className="form-label">Mot de passe</label>
                                            <div className="input-group has-validation">
                                                <span className="input-group-text" id="inputGroupPrepend"><FeatherIcon icon={"key"}></FeatherIcon> </span>
                                                <input type="password" name="password" className="form-control" onChange={this.handleInput} id="yourPassword" />
                                            </div>
                                        </div>
                                        
                                        <div className="col-12">
                                            <button className="btn btn-primary w-100" type='button' onClick={() => this.login()}>
                                            <div className='d-flex justify-content-center'>
                                                {
                                                    this.state.click && (
                                                        !this.state.error && (
                                                            <ReactLoading type="spokes" color="white" height={25} width={20} />
                                                        )
                                                    )
                                                }
                                                <span>Connexion</span>
                                            </div>    
                                            </button>
                                        </div>
                                        
                                        <div className="col-12">
                                        <p className="small mb-0">Vous n'avez pas un compte? <a href="https://evivesoma.vercel.app"  className="text-decoration-none">Contactez le responsable</a></p>
                                        </div>
                                    </form>

                                    </div>
                                </div>

                                </div>
                            </div>
                            </div>

                        </section>
                    )
                }
            </div>
        );
    }
}


export default Login;