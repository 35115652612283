import axios from "axios";
import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";
import FeatherIcon from 'feather-icons-react';
import Select from 'react-select';

class   LessonForm extends Component{

    state = {
        id : null,
        schedule_id : null,
        reason_evaluation : null,
        max : null,
        errors : {},
        isModal : false,
        title : null,

        schedules : [],
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }); 
    }

    async getSchedules () {
        var request = await axios.get("schedules/schedule");
        if(request.status == 200)
        {
            this.setState({
                schedules : request.data.data
            })
        }
    }

    storeEvaluation = () =>{
        var data = {
                schedule_id : this.state.schedule_id,
                reason_evaluation : "examen",
                max : this.state.max,
                operation : this.state.id ? "Editer" : "Ajouter",
            }
            
        var request = this.state.id ? axios.put('evaluations/'+this.state.id, data) : axios.post('evaluations', data);
        request.then((response) =>{
            this.props.showModal()
        }).catch((error) => {
            this.setState({
                errors : error.response.data.errors
            })
        })
    }
    
    edit=()=>  {
        this.form()
        this.getSchedules()
    }

    form=()=>{
        this.setState({
            id: this.props.formData ? this.props.formData.id : "",
            schedule_id: this.props.formData ? this.props.formData.schedule ? this.props.formData.schedule.id : "" : "",
            reason_evaluation: this.props.formData ? this.props.formData.reason_evaluation : "",
            max: this.props.formData ? this.props.formData.max : "",
            title : this.props.formData ? "Modifier la planification de l'examen" : "Planifier l'examen",
            errors : {},
        })
    }

    showModal = () => {
        this.setState({
            isModal : !this.state.isModal,
        })
    }

    handleCheck(val) {
        var user = JSON.parse(localStorage.getItem("user"));
        return user ? user.permissions ? (user.permissions ?? []).length > 0 ? user.permissions.some(item => val === item.permission) : false : false : false;
    }
    
    render(){
        var user = JSON.parse(localStorage.getItem("user"))

        var schedules = [], date_start = "", count = [];
        this.state.schedules.filter(examen => examen.schedulecategory.category == "Examen").map((schedule) =>{
            date_start = "";
            if(schedule.date_start)
                date_start = schedule.date_start
            if(schedule.time_start)
                date_start = date_start + " " + schedule.time_start +"#"
            if(schedule.date_end)
                date_start = date_start +  " " + schedule.date_end
            if(schedule.time_end)
                date_start = date_start + " " + schedule.time_end
            
            if(this.props.class == "" || this.props.class == schedule.establishmentsectionclasselesson.establishmentsectionclasse.id)
            {
                if(schedule.establishmentsectionclasselesson.staff.id == user.staff.id || this.handleCheck("Profil admin") == true)
                {
                    if(count.filter(item => item.category  == schedule.schedulecategory.category && item.lesson == schedule.establishmentsectionclasselesson.lesson.short_entitled_lesson && item.periode == schedule.periode.id ).length == 0)
                    {
                        count.push({
                            category : schedule.schedulecategory.category,
                            lesson : schedule.establishmentsectionclasselesson.lesson.short_entitled_lesson,
                            periode : schedule.periode.id
                        })
                        schedules.push({ value : schedule.id, label :  schedule.schedulecategory.category+ " "+schedule.establishmentsectionclasselesson.lesson.entitled_lesson + "#"+ schedule.periode.id+ "P#"+ schedule.establishmentsectionclasselesson.establishmentsectionclasse.short_entitled_class + " "+ schedule.establishmentsectionclasselesson.establishmentsectionclasse.title + " "+ schedule.establishmentsectionclasselesson.establishmentsectionclasse.short_entitled_section + "#" + date_start }) // +" " +schedule.time_start +" "+ schedule.time_end
                    }
                }
            }
        })
        
        return(
            <div>
            <Modal 
                show={this.props.isModal} 
                onHide={this.props.showModal}
                size="lg"
                onEntered={this.edit}
                backdrop={"static"}>
                <Modal.Header>
                    <Modal.Title>{ this.state.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="row">
                    
                    <div className="form-group   mt-2">
                        <label className="font-weight-bolder">Horaire de l'examen </label>
                        <div className="input-group">
                            <Select className="form-control form-control-sm"
                                value = {
                                    schedules.filter(option => option.value == this.state.schedule_id)
                                }
                                defaultValue={this.state.schedule_id}
                                onChange={
                                    (select) => {
                                        this.setState({
                                            schedule_id : select.value
                                        }); 
                                    }
                                }
                                options={schedules}
                            />
                        </div>
                        <span className="text-danger text-small">{ this.state.errors.schedule_id }</span>
                    </div>
                    
                    <div className="  mt-2 mt-2">
                        <div className="form-group has-icon-left">
                            <label htmlFor="nom-id-icon">Max</label>
                            <div className="position-relative mt-1">
                                <input type="number" className="form-control" placeholder="Max"  onChange={this.handleInput} name="max"  value={this.state.max} id="nom-id-icon" />
                                <div className="form-control-icon">
                                    <FeatherIcon icon="box"></FeatherIcon>
                                </div>
                            </div>
                            <span className="text-danger text-small small">{ this.state.errors.max }</span>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <button onClick={this.props.showModal} className="btn btn-outline-secondary btn-sm"><FeatherIcon icon="corner-down-left"></FeatherIcon> Fermer</button>
                    {
                        this.handleCheck("Ajouter evaluation") == true || this.handleCheck("Modifier evaluation") == true ? (
                        <button onClick={() => this.storeEvaluation()} className="btn btn-sm btn-outline-primary"><FeatherIcon icon="check-square"></FeatherIcon> Sauvegarder</button>
                        ) : ""
                    }
                </Modal.Footer>
            </Modal>
            
 
        </div>
        )
    }
}
export default LessonForm;