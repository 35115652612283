import React, {Component} from "react";
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js'
import Schedule from "./st/schedules/Schedule";
import axios from "axios";
Chart.register(...registerables)

class Dashboard extends Component{

    state = {
        
        labels :[],
        data : []
      }

      componentDidMount(){
          this.getStudents();
      }
      async getStudents(){
        var students = await axios.get('students/statistic/12');

        if(students.status === 200)
        {
            var labels = [], data = [];
            students.data.data.map((student) => {
                labels.push(student.user.matricule + " " + student.user.last_name);
                data.push(student.studentmax > 0 ? ((student.studentsuccess * 100 ) / student.studentmax).toFixed(1) : 0);
            })

            this.setState({
                labels : labels,
                data : data
            })
        }
      }

    render(){
        
        var data = {
            labels: this.state.labels,
            datasets: [
            {
                label: 'points',
                backgroundColor: '#045fc0',
                borderColor: 'rgba(0,0,0,1)',
                borderWidth: 2,
                data: this.state.data
            }
            ],
        }
        return(
            <div>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h4 className="h4 font-monospace">Dashboard</h4>
                    <div className="btn-toolbar mb-2 mb-md-0">
                        <button type="button" className="btn btn-sm btn-outline-secondary dropdown-toggle">
                            <span data-feather="calendar"></span>
                            Année encours
                        </button>
                    </div>
                </div>

                {
                    data.labels.length > 0 && (
                        <Bar
                            data={data}
                            options={{
                            plugins: {
                                title: {
                                display: true,
                                text: "12 premiers élèves"
                                },
                                legend: {
                                display: true,
                                position: "bottom"
                            }
                            }
                            }}
                        />
                    )
                }

            <Schedule />
            </div>
        )
    }
}
export default Dashboard;