
import React from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Navbar from './partials/Navbar';
import Sidebar from './partials/Sidebar';
import RouterPage from "./RouterPage";

function App() {
  const token = localStorage.getItem("token")
  var padding = "p-3";
  if(!token && window.location.href != "https://evivesomastat.vercel.app/login")
    window.location.replace(`https://evivesomastat.vercel.app/login`)
  else if(window.location.href == "https://evivesomastat.vercel.app/login")
    padding = "";

  return (
    <Router>
      <div className="container-fluid">
            <div className="row flex-nowrap">
              { token && (<Sidebar /> ) }
              <div className="col p-0 m-0">
              { token && (<Navbar />)}
                  
                  <div className={"row col-md-12 " +padding}>
                    <RouterPage />
                  </div>
              </div>
            </div>
      </div>
    </Router>
  );
}

export default App;
