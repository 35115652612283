import axios from "axios";
import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";
import FeatherIcon from 'feather-icons-react';

class   QuestionForm extends Component{

    state = {
        id : null,
        question : null,
        answer : null,
        errors : {},
        
        isModal : false,
        title : null,
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }); 
    }

    storeQuestion = () =>{
        var data = {
            question : this.state.question,
            answer : this.state.answer,
            evaluation_id : this.props.formData.evaluation_id,
            // operation : this.state.id ? "Editer" : "Ajouter",
        }

        var request = this.state.id ? axios.put('questions/'+this.state.id, data) : axios.post('questions', data);
        request.then((response) =>{
            this.props.showModal()
        }).catch((error) => {
            this.setState({
                errors : error.response.data.errors
            })
        })
    }
    
    edit=()=>  {
        this.form()
    }

    form=()=>{
        
        this.setState({
            id: this.props.formData ? this.props.formData.id : "",
            question: this.props.formData ? this.props.formData.question : "",
            title : "Ajouter une question",
            errors : {},
        })
    }

    showModal = () => {
        this.setState({
            isModal : !this.state.isModal,
        })
    }

    render(){
        
        return(
            <div>
            <Modal 
                show={this.props.isModal} 
                onHide={this.props.showModal}
                size="lg"
                onEntered={this.edit}
                backdrop={"static"}>
                <Modal.Header>
                    <Modal.Title>{ this.state.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="row">
                    
                    <div className="col-12 mt-2">
                        <div className="form-group ">
                            <label htmlFor="postnom-id-icon">Question</label>
                            <div className="position-relative mt-1">
                                <textarea
                                    value={this.state.question}
                                    onChange={this.handleInput}
                                    name="question"
                                    rows={2}
                                    cols={4}
                                    className="form-control  "
                                />
                            </div>
                            <span className="text-danger text-small small">{ this.state.errors.question }</span>
                        </div>
                    </div>

                    <div className="col-12 mt-2">
                        <div className="form-group ">
                            <label htmlFor="postnom-id-icon">Reponse</label>
                            <div className="position-relative mt-1">
                                <textarea
                                    value={this.state.answer}
                                    onChange={this.handleInput}
                                    name="answer"
                                    rows={2}
                                    cols={4}
                                    className="form-control  "
                                />
                            </div>
                            <span className="text-danger text-small small">{ this.state.errors.answer }</span>
                        </div>
                    </div>
                     

                </Modal.Body>
                <Modal.Footer>
                    <button onClick={this.props.showModal} className="btn btn-outline-secondary btn-sm"><FeatherIcon icon="corner-down-left"></FeatherIcon> Fermer</button>
                    <button onClick={() => this.storeQuestion()} className="btn btn-sm btn-outline-primary"><FeatherIcon icon="check-square"></FeatherIcon> Sauvegarder</button>
                </Modal.Footer>
            </Modal>
            
 
        </div>
        )
    }
}
export default QuestionForm;