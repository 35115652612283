import React, {Component} from "react";
import { Link } from "react-router-dom";
import FeatherIcon from 'feather-icons-react';
import Resizer from "react-image-file-resizer";
import axios from "axios";

class Profile extends Component{

    state = {
        loading : false,
        loadingForm : false,
        image : [],
        password : "",
        confirmed : "",
        errors : "",
        valid : ""
    }

    handleInputFile = (e) => {

        this.setState({
            image : []
        })
        for(var i = 0 ; i < e.target.files.length; i++)
        {
            this.setState({
                loading : true
            })
            var img = "";
            try {
            img =  Resizer.imageFileResizer(
                  e.target.files[i],
                  1500,
                  1500,
                  "JPEG",
                  100,
                  0,
                  (uri) => {
                        this.state.image.push(uri);
                    this.setState({ image: this.state.image });
                  },
                  "base64",
                  1000,
                  1000
                );
            } catch (err) {
                console.log(err);
            }
        }
        this.setState({
            loading : false,
            loadingForm : false
        })
    }


    storeImage = () => {
        this.setState({
            loadingForm : true,
        })
        var user = JSON.parse(localStorage.getItem('user'));
        let dataImage = new FormData()
        
        dataImage.append("avatar[]", this.state.image[0]);
        
        console.log(this.state.image)
        
        axios.put(`users/avatar/` + user.id, {avatar : this.state.image[0] }).then(response => {
            this.setState({
                image : [],
                loadingForm : false,
                errors : ""
            })
            localStorage.setItem("user", JSON.stringify(response.data.user));
        })
        .catch(error => {
            this.setState({
                errors: error.response.data.errors,
                loadingForm : false
            });
        });
    }

    storePassword = () => {
        var data = {
            password : this.state.password,
            password_confirmation : this.state.confirmed,
        }
        var user = JSON.parse(localStorage.getItem('user'));
        axios.put("users/" + user.id, data).then((response) =>{
            this.setState({
                valid : this.state.password ? "Mot de passe bien modifié" : "", 
                password : "",
                confirmed : "",
                errors : "",
            })
        }).catch((error) =>{
            this.setState({
                errors: error.response.data.errors,
                valid : ''
            })
        })
    }
    

    hundelInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    render(){
        var user = JSON.parse(localStorage.getItem('user'));
        return(
            <div className="row">
                <h4 className="h4 font-monospace">Profil</h4>
                <div className="col-md-7 col">
                    <div className="card shadow mb-3 overflow-hidden rounded rounded-3">
                        <div className="card-body d-md-flex">
                            <div className="image mr-3">
                                <Link to={'#'}  className="text-decoration-none">
                                    <img src={user.avatar} className="rounded-circle img-fluid me-2" width={user.avatar && ("100px")} />
                                </Link>
                            </div>
                            <div>
                                <div><Link to={'#'}  className="text-decoration-none"><b>{user.matricule + "/" + user.identity_number}</b></Link></div>
                                <div>{user.first_name + " "+ user.last_name}</div>
                                <div><a href={"tel:"+user.phone_number} rel="noopener noreferrer" target="_blank" className="text-dark text-decoration-none"> <FeatherIcon icon="phone" size="16"></FeatherIcon> {user.phone_number}</a> </div>
                                <div><a href={"mailto:"+user.email} rel="noopener noreferrer" target="_blank" className="text-dark text-capitalize text-decoration-none"> <FeatherIcon icon="mail" size="16"></FeatherIcon> {user.email}</a> </div>
                                <div className="text-end m-1">

                                    {
                                        user.profiles &&(
                                            user.profiles.map((profile) =>{
                                                if(profile.status == 1)
                                                {
                                                    return(
                                                        <span key={profile.id} className="badge bg-success small rounded-3 mx-1" type="button">{profile.profile}</span>
                                                    )
                                                }
                                            })
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-5">
                    <div className="card shadow mb-3">
                        <div className="card-body">
                            <h6 className="h6 font-monospace">Photo de profil</h6>
                            <div className="">
                                <label>Photo</label>
                                {
                                    this.state.image.map((img, index) => {
                                        return(
                                            <img src={img} alt="" key={index} className="rounded-circle img-fluid" width={"100"} />
                                        )
                                    })
                                }

                                <input type={"file"} className="form-control form-control-sm" onChange={this.handleInputFile} />
                            </div>
                                {
                                    this.state.loading && (
                                        <i className={"fas fa-spin"}>
                                        <FeatherIcon icon={"loader"} className="d-inline" ></FeatherIcon></i>
                                    )
                                }
                            <div className=" pt-2 text-center">
                                <button className="btn btn-sm btn-primary py-1 rounded rounded-3" onClick={() => this.storeImage()}>Modifier la photo</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-10">
                    <div className="card shadow mb-3">
                        <div className="card-body row">
                            <h6 className="h6 font-monospace">mot de passe</h6>
                            <span className="text-primary text-small">{this.state.valid}</span>
                            <div className="col-md-6">
                                <label>Nouveau mot de passe</label>
                                <input type={"password"} className="form-control form-control-sm" required={true} name="password" onChange={this.hundelInput} />
                                <span className="text-danger text-small">{this.state.errors.password}</span>
                            </div>
                            <div className="col-md-6">
                                <label>Confirmer le mot de passe</label>
                                <input type={"password"} className="form-control form-control-sm" required={true} name="confirmed" onChange={this.hundelInput} />
                                <span className="text-danger text-small">{this.state.errors.confirmed}</span>
                            </div>
                            <div className=" pt-2 text-center">
                                <button className="btn btn-sm btn-primary py-1 rounded rounded-3" onClick={() => this.storePassword()}>Modifier le mot de passe</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
export default Profile;