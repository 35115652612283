import React, {Component} from "react";

class Footerpdf extends Component{

    render(){
        var establishment = JSON.parse(localStorage.getItem('establishment'))
        return(
            <div className="footerpdf">
                <div className="contact">
                    <center>
                        { establishment.email} { establishment.tel} / { establishment.adresse}<br />
                        { establishment.short_name} ({ establishment.name}) { establishment.slogan}
                    </center>
                </div>
            </div>
        )
    }
}

export default Footerpdf