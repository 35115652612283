import axios from "axios";
import React, {Component} from "react";
import { Link } from "react-router-dom";
import FeatherIcon from 'feather-icons-react';
import Listprint from "../../Listprint";
import { Helmet } from 'react-helmet';
import Loading from "../../Loading";

class Archive extends Component{

    state = {
        isModal : false,
        loading : false,
        formData : "",
        archives : [],
        isModal : false,
        formData : {},
        classe : "",
        columns : [
            {
                label: 'Matr',
                field: 'matricule',
                sort: 'asc',
                width: 50
            },
            {
                label: 'Eleve',
                field: 'student',
                sort: 'asc',
                width: 200
            },
            {
                label: 'pourcent',
                field: 'studentsuccess',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Année',
                field: 'year',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Date',
                field: 'created_at',
                sort: 'asc',
                width: 120
            }
        ]
    }

    componentDidMount(){
        this.getArchives();
    }

    async getArchives(){
        var archives = await axios.get('years/archives');
        if(archives.status === 200)
        {
            this.setState({archives : archives.data.data, loading : true}) 
        }
    }

    showModal = (student = null, classe=false) => {
        this.setState({
            isModal : !this.state.isModal,
            formData : student,
            classe : classe ? student : null
        })
        this.setState({
            archives : []
        })
        this.getArchives()
    }


    render(){
        var students = 0;
        var labels = [], data = [], rows = []

        var archives =
        this.state.archives.map((year) => {
            students = year.students.filter(student => student.operation === "Archiver").length
            labels.push(year.scolary_year)
            data.push(students)
            return(
                <div  key={year.id}>
                    <li className="widget-todo-item list-group-item m-2 border border-secondary rounded-3 shadow mb-2">
                        <div
                            className="widget-todo-title-wrapper d-flex justify-content-between align-items-center mb-50">
                            <div className="widget-todo-title-area d-flex align-items-center">
                                <FeatherIcon icon="package" className='cursor-move'></FeatherIcon> 
                                 <label htmlFor={year.id}><span className="widget-todo-title ml-50 fw-bolder" >{year.scolary_year} </span></label>
                            </div>
                            <div className="widget-todo-item-action d-flex align-items-center">
                                <b><FeatherIcon icon="book-open" size="18" className='cursor-move'></FeatherIcon></b>
                            </div>
                        </div>
                    </li>
                    <div className="col-md-12 fluid-content">
                    {
                        year.establishmentsections.map((establishmentsection) => {
                            return(
                                <div className="card m-2 shadow rounded  rounded-3 " key={establishmentsection.id}>
                                    <div className="card-body">
                                        <h6 className="fw-bolder font-monospace text-center">{establishmentsection.section.entitled_section + " "+establishmentsection.section.short_entitled_section}</h6>
                                        
                                        {
                                            establishmentsection.establishmentsectionclasses.map((establishmentsectionclasse) => {
                                                rows = []
                                                return(
                                                    <div className="col-md-12 mb-4 overflow-hidden" key={establishmentsectionclasse.id}>
                                                        <div className="card  mb-2 rounded rounded-3">
                                                            <div className="card-body">

                                                                <div className="text-center m-2 font-monospace "> 
                                                                    <span className="p-1 px-2 border border-1 border-dark rounded rounded-3">{establishmentsectionclasse.classe.short_entitled_class} {establishmentsectionclasse.title} <small>{establishmentsection.section.short_entitled_section} </small> </span> 
                                                                </div>
                                                                <div className="text-end text-primary"> {establishmentsectionclasse.status} </div>
                                                                </div>
                                                                {
                                                                    establishmentsectionclasse.students.filter(student => student.operation === "Archiver" && student.scolary_year === year.scolary_year).map((student) => {
                                                                        rows.push(
                                                                            {
                                                                                matricule : student.user && (<Link to={'/students/'+student.id}  className="text-decoration-none text-primary">{student.user.matricule}</Link>),
                                                                                student : student.user && (student.user.last_name +" "+ student.user.family_name  +" "+ student.user.first_name ),
                                                                                studentsuccess :  student.studentmax > 0 ? ((student.studentsuccess * 100 ) / student.studentmax).toFixed(1)+"%" : "0%",
                                                                                year : year.scolary_year,
                                                                                created_at : <small className="fw-light">{student.updated_at}</small>,
                                                                                // status : <span><span className="px-1 border border-1 border-success text-success size-13 rounded rounded-3">{ student.operation }</span>
                                                                                //             <button onClick={ () => this.showModal(student)} className="table_action btn btn-outline-primary rounded-3 btn-sm mx-1" title="desarchiver"><FeatherIcon icon="corner-down-left" size={"13"}></FeatherIcon> </button> 
                                                                                //         </span>,
                                                                            }
                                                                        )
                                                                    })
                                                                }
                                                                
                                                                <div>
                                                                {
                                                                    rows.length > 0 && (
                                                                        <Listprint columns={this.state.columns} rows={rows} title={"Archive des élèves de " + establishmentsectionclasse.classe.short_entitled_class + " "+ establishmentsectionclasse.title + "  "+ establishmentsection.section.short_entitled_section +" # "+ year.scolary_year}/>
                                                                    )
                                                                }                                                                    
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        
                                    </div>
                                </div>
                            )
                        })
                    }
                    </div>
                </div>
            )
        })
        return(
            <div>
                <Helmet>
                    <title>EviveSoma | archived data</title>
                </Helmet>
            <div className=" pt-3 pb-2 mb-3 border-bottom">
                <h6 className="h4 font-monospace">Archive scolaire statistique</h6>
            </div>

            <div className=" mb-4 overflow-auto">
                <div className="">
                    <ul className="widget-todo-list-wrapper p-0">
                        { this.state.loading ?  archives : <Loading /> }
                    </ul>
                </div>
            </div>
            
        </div>
        )
    }
}
export default Archive;